.card-drop-target-divider{
    position: relative;
    z-index: 10;

    width: 0;
    margin: 0 -100px;
    padding: 100px;

    &.disabled-target{
        display: none;
    }

    &.list-card-drop-target{
        margin: -100px 0;
        padding: 100px 0;
        width: 100%;
        height: 0;
    }

    .card-drop-target-divider-inner{
        position: relative;
        background-color: $primary;
        transition-property: opacity;
        transition-duration: 0.1s;

        margin-left: -10px;
        width: 20px;
        height: 75%;

        transform: scale(.8);

        opacity: 0%;

        &.list-card-drop-target{
            transform: none;

            margin: -10px auto;
            margin-top: -25px;
            width: 90%;
            height: 0;
            padding: 10px;
        }

        &.item-dragged-onto{
            opacity: 100%;
        }
    }
}


@media (max-width:$screen-sm) {
    .card-drop-target-divider{

        margin: -90px 0;
        padding: 100px 0;
        height: auto;
        width: 100%;

        .card-drop-target-divider-inner{

            margin: 0 auto;
            width: 90%;
            height: 20px;

            transform: none;

            opacity: 0%;

            &.item-dragged-onto{
                opacity: 100%;
            }
        }
    }
}

.book-sequence-number {
    z-index: 400;
}

.book-details-title {
    @extend .h5;
}

.product-count{
    position: absolute;
    top: 12px;
    left: 12px;
    height: 32px;
    width: 65px;

    text-align: center;

    border: 1px solid $gray;
    background-color: $gray;
    font-size: $font-size-base;
    font-weight: 600;
    line-height: 32px;
    padding: 0;
    border-radius: 3px;
    color: $white-color;
    letter-spacing: 0.025em;
    white-space: nowrap;

    &:not(.product-count:focus) {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
        }
    }

    appearance: textfield;
    
}

.product-count:focus{
    appearance: none;
    width: 80px;
}

.book-item-error{
    color: $danger;
    font-weight: bold;
}

.book-details--remove-warning {
    word-wrap: anywhere;
}

.book-versions-datetime {
    @extend .h4;
}