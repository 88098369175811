@mixin icon-content($icon) {
    content: map.get($feather-icons-map, $icon);
}

@mixin pe-icon-7-content($icon) {
    content: map.get($pe-icon-7-stroke-map, $icon);
}

@mixin icon-font() {
    font-family: "feather" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    user-select: none;
}

@mixin pe-icon-7-font() {
    font-family:"Pe-icon-7-stroke" !important;
    font-weight:400;
    font-style:normal;
    font-variant:normal;
    text-transform:none;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;

    user-select: none;
}

@mixin icon($icon) {
    @include icon-font();
    @include icon-content($icon);
}
