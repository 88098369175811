.distribution-list-edit-modal .address--address-block[data-in-global="true"]> :first-child::after {
    @include icon('globe');
    padding-left: 0.5em;
    opacity: 0.75;
    color: rgb(54, 113, 126);
}

.distribution-list-edit-modal .address--address-block[data-is-default="true"]> :first-child::after {
    @include icon('star');
    padding-left: 0.5em;
    color: rgb(141, 141, 22);
    font-weight: 900;
}

.distribution-list-edit-modal--search-section {
    border-bottom: 1px solid $gray-light;
    @include padding(0.5);
}

.distribution-list-edit-modal--mobile-search {
    @extend .d-lg-none;
}

.distribution-list-edit-modal--search {
    @extend .d-none;
    @extend .d-lg-block;
}

.distribution-list-edit-modal--search-filter-radio {
    display: flex;
    align-items: center;

    label {
        margin-left: 0.5em;
    }
}