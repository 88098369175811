.new-ldap-user-contact-info {
    margin-top: ceil($grid-vertical-step * 1.5); //~36px
    margin-bottom: ceil($grid-vertical-step * 1); //~24px

    .address-form {
        margin-top: ceil($grid-vertical-step * 1); //~24
    }

    .button-container {
        display: flex;
        justify-content: center;
    }
}
