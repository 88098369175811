.address-select-modal .address--address-block[data-in-global="true"]> :first-child::after {
    @include icon('globe');
    padding-left: 0.5em;
    opacity: 0.75;
    color: rgb(54, 113, 126);
}

.address-select-modal .address--address-block[data-is-default="true"]> :first-child::after {
    @include icon('star');
    padding-left: 0.5em;
    color: rgb(141, 141, 22);
    font-weight: 900;
}

.address-select-modal--mobile-search {
    @extend .d-lg-none;
}

.address-select-modal--sidebar {
    @include padding-top(0.5);

    & .address-select-modal--search {
        @extend .d-none;
        @extend .d-lg-block;
        @include margin-bottom(1.5);
    }
}