.licenseAgreement {

    h1 {
        margin: {
            top: 15px;
        }

        text-align: center;
    }

    .license-text {
        background-color: $gray-lighter;
        padding: 15px;
        height: 450px;
        overflow-y: scroll;

        margin: {
            top: 25px;
            bottom: 25px;
        }
    }

    .jumpToLinks {
        text-align: center;

        margin: {
            top: 25px;
        }

        a {
            display: block;

            margin: {
                top: 10px;
                bottom: 10px;
            }
        }
    }

    .buttons {
        input {
            margin: {
                bottom: 10px;
            }
        }

        label {
            padding: {
                left: 10px;
            }
        }

        .button-row {
            margin: {
                top: 25px;
                bottom: 25px;
            }

            text-align: center;
        }
    }
}