//
// Scaffolding
// --------------------------------------------------

html * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: {
        position: center;
        color: $body-bg;
        repeat: no-repeat;
        size: cover;
    }
    color: $body-color;
    font: {
        family: $font-family-base;
        size: $font-size-md;
        weight: $font-weight-base;
    }
    line-height: $line-height-base;
    text-transform: $text-transform-base;
}

// Links
a {
    color: $link-color;
    text-decoration: underline;

    &:hover {
        color: $link-hover-color;
        text-decoration: none;
    }
    // &:focus {
    //     outline: none;
    // }
}

.small,
small {
    font-size: 85%;
}

// Navigation Links
.navi-link {
    transition: color 0.3s;
    color: $nav-link-color;
    text-decoration: none;
    &:hover {
        color: $nav-link-hover-color !important;
    }
}
.navi-link-light {
    transition: opacity 0.3s;
    color: $white-color;
    text-decoration: none;
    &:hover {
        color: $white-color;
        opacity: 0.6;
    }
}

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure,
svg {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
}

/* Responsive iframes */
iframe {
    width: 100%;
}

// /* Box Model */
// * {
//     box-sizing: border-box;
// }
// *::before,
// *::after {
//     box-sizing: border-box;
// }

// Horizontal rules
hr {
    margin: 0;
    border: 0;
    border-top: 1px solid $border-color;
    &.hr-light {
        border-top-color: $border-light-color;
    }
}

// Pre tag
pre {
    display: block;
    padding: 15px;
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    background-color: $gray-lighter;
}

// Text Selection Color
::selection {
    background: $gray-darker;
    color: $white-color;
}
::-moz-selection {
    background: $gray-darker;
    color: $white-color;
}

// Image with caption
// -------------------------------------------------------

figure {
    position: relative;
    margin: 0;
    figcaption {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: floor($grid-vertical-step * .5); // ~12px
        font-size: $font-size-sm;
    }
}

/* Bootstrap Overrides -- Removed by RSA; .container changes handled by $container-max-widths variable instead; .container-fluid is unused*/
// @media (min-width: $screen-xl) {
//     .container {
//         width: 1170px;
//         max-width: 1170px;
//     }
// }
// @media (max-width: $screen-xl) {
//     .container {
//         width: 100% !important;
//         max-width: 100% !important;
//     }
// }
// .container-fluid {
//     max-width: 1920px;
//     margin: {
//         right: auto;
//         left: auto;
//     }
//     padding: {
//         right: 30px;
//         left: 30px;
//     }
//     @media (max-width: $screen-xl) {
//         padding: 0 15px;
//     }
// }
