.review-dropdown {
    right: 0;
    width: 280px;
    padding: 20px;

    small {
        display: block;
        padding-top: 3px;
        color: $gray;
    }

    .review-type {
        color: $primary;
    }

    .entry {
        &:hover {
            background-color: $gray-lighter;
        }

        grid-template-columns: auto max-content;
        position: relative;
        padding-right: 20px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ddd;
    }
}