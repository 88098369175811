.react-datepicker__month {
    margin: 0.5em;
    text-align: center;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day {
    display: inline-block;
    width: 2.1em;
    line-height: 2.1em;
    text-align: center;
    margin: 0.3em;
}

.react-datepicker__day {
    cursor: pointer;
    border-radius: $border-radius-sm;
    color: $gray-darker;
    &:hover:not(.react-datepicker__day--disabled) {
        @include bg-color-contrast-fg(tint-color($primary, 80%));
    }

    &.react-datepicker__day--outside-month {
        color: $gray;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--in-range {
        @include bg-color-contrast-fg($primary);

        &:hover {
            @include bg-color-contrast-fg(shade-color($primary, 10%));
        }
    }

    &.react-datepicker__day--keyboard-selected {
        @include bg-color-contrast-fg(shade-color($primary, 10%));
    }
}

.react-datepicker__day--today {
    font-weight: bold;
}


.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,.react-datepicker__day--keyboard-selected) {
    @include bg-color-contrast-fg(tint-color($primary, 80%));
}

.react-datepicker__day--disabled {
    cursor: default;
    opacity: 0.55;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent;
}
