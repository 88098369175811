.checkout--billing--one-account-code-warning {
    margin: 30px 0px;
}

.checkout--billing--total-split-percentage {
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    display: flex;

    .complete {
        color: $brand-success;
    }


    .incomplete {
        color: $brand-danger;
    }
}

.checkout--billing--requiredCreditCardNote {
    text-align: left;
}