.topbar {
    display: flex;
    justify-content: space-between;

    .site-logo {
        align-self: center;
    }

    .site-logo-mobile {
        display: none;
    }

    .site-branding,
    .search-box-wrap,
    .toolbar {
        display: flex;
    }
}

// Use the small logo on phones and small devices or the cart button will be blown off the page
// The logoSmall has to be a maximum of 130px in width
@media (max-width: $screen-sm) {

    .site-header .site-branding {
        .site-logo {
            display: none;
        }

        .site-logo-mobile {
            display: block;
        }
    }
}