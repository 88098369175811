
.sure-preview--tab-edit-modal--tabstock-selector {
    >button {
        text-align: left;
        color: var(--bs-modal-color);
        border-color: var(--bs-gray-300);
        &:hover, &:focus{
            background-color: initial;
            color: inherit;
        }
        >i {
            position: absolute;
            font-size: 1.5em;
            right: 0.625rem;
            bottom: 0.4em;
        }
    }
    >label {
        top: 8px;
    }
}