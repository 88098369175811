.placeholder-text {
    position: relative;
    max-width: 100%;
}

.placeholder-text--block {
    min-width: 8em;
}

.placeholder-text--inline {
    display: inline-block;
}

.placeholder-text > svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.placeholder-text-debug .placeholder-text[data-placeholder-for] {
    &::after {
        content: attr(data-placeholder-for);
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        white-space: nowrap;
        left: 0.5em;
        max-width: calc(100% - 1em);
        padding: 0 0.4em;
        top: 15%;
        font-size: 0.75em;
        background-color: #f0f;
        color: #000;
    }
}
