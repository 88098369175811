.sure-preview--grid-view {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: $grid-gutter-width * -0.5;
    margin-left: $grid-gutter-width * -0.5;
    background-color: $body-bg;
    color: $body-color;

    .sure-preview--grid-view--close {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 24px;
        box-shadow: 0 0 8px #fff;
        background-color: rgba(255,255,255,0.75);
        border-radius: $border-radius-base;
        margin: 0.5rem 0.75rem;
        padding: 0 0.25rem;

        .icon-btn-inline:not(:first-child) {
            margin-left: 1rem;
        }
    }
}