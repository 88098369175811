//
// Shop Styles
// --------------------------------------------------

// Product Card
// -------------------------------------------------------

.product-card-container {
    margin-bottom: 30px;
}

.product-card {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid $border-color;
    border-radius: $border-radius-base;
    background-color: $white-color;
    display: flex;
    flex-direction: column;


    &.draggable {
        cursor: grab;
    }

    .product-card-body {
        padding: 0 18px 18px;
        text-align: center;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        word-break: break-word;

        & :last-child {
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
    }

    .product-thumb {
        display: flex;
        aspect-ratio: 1/1;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 18px;

        img,
        svg {
            border-radius: $border-radius-base;
            margin: auto;
        }

        &.thumb-shadow {

            img,
            svg {
                box-shadow: 0 0 5px 1px rgba-var($black-color, 0.25);
            }
        }
    }

    .product-category {
        width: 100%;
        margin-bottom: 6px;
        font-size: $font-size-xs;

        >a {
            transition: color 0.2s;
            color: $gray;
            text-decoration: none;

            &:hover {
                color: $product-title-hover-color;
            }
        }
    }

    .product-title {
        margin-bottom: 18px;
        word-wrap: anywhere;

        font: {
            size: $product-title-font-size;
            weight: normal;
        }

        * {
            white-space: break-spaces !important;
        }

        >a {
            transition: color 0.3s;
            color: $product-title-color;
            text-decoration: none;

            &:hover {
                color: $product-title-hover-color;
            }
        }
    }

    button.product-title {
        border: none;
        background: none;
    }

    .product-price {
        display: inline-block;
        color: $product-price-color;

        font: {
            size: $product-price-font-size;
            weight: normal;
        }

        >del {
            margin-right: 5px;
            color: $gray;
        }
    }

    .product-buttons {
        padding-bottom: 12px;
        text-align: center;
    }

    .product-button-group {
        display: table;
        width: 100%;
        border-top: 1px solid $border-color;
        table-layout: fixed;

        .product-button {
            display: table-cell;
            position: relative;
            height: 62px;
            padding: 10px;
            transition-property: background-color, opacity;
            transition-duration: 0.3s;
            border: 0;
            border-right: 1px solid $border-color;
            background: none;
            color: $body-color;
            overflow: hidden;
            vertical-align: middle;

            text: {
                align: center;
                decoration: none;
            }

            &:first-child {
                border-bottom-left-radius: $border-radius-base;
            }

            &:last-child {
                border: {
                    right: 0;
                    bottom-right-radius: $border-radius-base;
                }
            }

            .product-button-icon,
            .product-button-label {
                transition-property: opacity, transform;
                transition-duration: 0.3s;
            }

            .product-button-icon {
                display: inline-block;
                position: relative;
                margin-top: 5px;
                font-size: $font-size-lead; // ~18px
            }

            .product-button-label {
                display: block;
                position: absolute;
                bottom: 9px;
                left: 0;
                width: 100%;
                transform: translateY(12px);
                font-size: $font-size-xs; // ~12px
                white-space: nowrap;
                opacity: 0;
            }

            &.always-hover,
            &:hover,
            &:focus {
                background-color: $gray-lighter;

                .product-button-icon {
                    transform: translateY(-10px);
                }

                .product-button-label {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            &.btn-wishlist.active>i {
                color: $brand-danger;
            }

            &.btn-compare.active>i::after {
                display: block;
                position: absolute;
                top: -5px;
                right: -6px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: $brand-success;
                content: "";
            }

            &:not([role="button"]) {
                cursor: not-allowed;
                opacity: 0.5;

                &.always-hover {
                    opacity: 1;
                    background-color: white;

                    .product-button-icon {
                        color: $brand-primary;
                    }
                }
            }
        }
    }

    .rating-stars {
        position: absolute;
        top: 9px;
        right: 12px;
    }

    @media (min-width: $screen-sm) {
        &.product-list {
            display: grid;
            grid-template-columns: $catalog-thumb-size auto;
            padding: 0;
            margin-bottom: 30px;

            .product-card-body {
                padding: 32px 22px 18px;
                text-align: left;
            }

            .product-card-inner {
                display: grid;
                grid-template-rows: auto min-content;
                border-left: 1px solid $border-color;
            }
        }
    }
}

.touvents .product-card .product-button-group {
    .product-button>i {
        transform: translateY(-10px);
    }

    .product-button>span {
        transform: translateY(0);
        opacity: 1;
    }
}

.product-badge {
    height: 24px;
    padding: 0 14px;
    border-radius: $border-radius-xs;
    color: $white-color;

    font: {
        size: $font-size-xs;
        weight: 400;
    }

    letter-spacing: 0.025em;
    line-height: 24px;
    white-space: nowrap;

    margin: {
        right: 5px;
        bottom: 5px;
    }

    pointer-events: auto;
}

// Product Rating
// -------------------------------------------------------

.rating-stars {
    display: inline-block;

    >i {
        display: inline-block;
        margin-right: 2px;
        color: tint-color($gray, 40%);
        font-size: $font-size-xs;

        &.filled {
            color: $brand-warning;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// Shop Toolbar
// -------------------------------------------------------

.shop-toolbar {
    display: table;
    width: 100%;

    >.column {
        display: table-cell;
        vertical-align: middle;

        &:last-child {
            text-align: right;
        }
    }

    @media (max-width: $screen-sm) {
        >.column {
            display: block;
            width: 100%;
            text-align: center;

            &:last-child {
                padding-top: $grid-vertical-step;
                text-align: center;
            }
        }
    }
}

.shop-sorting {
    .item-count {
        padding: 8px 0;
        vertical-align: middle;
        display: inline-block;
    }
}

.shop-view {
    display: inline-block;
    @include clearfix;

    >button,
    >a {
        display: inline-block;
        font-size: 20px;
        width: $shop-view-size;
        height: $shop-view-size;
        margin-left: -1px;
        transition: background-color 0.35s;
        border: 1px solid $border-color;
        border-radius: $border-radius-base;
        background-color: $shop-view-bg-color;
        color: $shop-view-color;

        &:first-child {
            border: {
                top-right-radius: 0;
                bottom-right-radius: 0;
            }
        }

        &:last-child {
            border: {
                top-left-radius: 0;
                bottom-left-radius: 0;
            }
        }

        &:hover {
            background-color: $shop-view-hover-bg-color;
        }

        &.active {
            border-color: $shop-view-active-bg-color;
            background-color: $shop-view-active-bg-color;
            color: color-contrast($shop-view-active-bg-color);
            cursor: default;
        }
    }
}

// Shopping Cart / Wishlist
// -------------------------------------------------------

.shopping-cart,
.wishlist-table,
.order-table {
    margin-bottom: floor($grid-vertical-step * .833); //~20px

    .table {
        margin-bottom: 0;
    }

    .btn {
        margin: 0;
    }

    >table>thead>tr,
    >table>tbody>tr {

        >th,
        >td {
            vertical-align: middle !important;
        }
    }

    >table thead th {
        padding: {
            top: 17px;
            bottom: 17px;
        }

        border-width: 1px;
    }

    .remove-from-cart {
        display: inline-block;
        color: $brand-danger;
        font-size: $font-size-lead;
        line-height: 1;
        text-decoration: none;
    }

    .count-input {
        display: inline-block;
        width: 100%;
        width: 86px;
    }

    .product-item {
        display: table;
        width: 100%;
        min-width: 150px;

        margin: {
            top: 5px;
            bottom: 3px;
        }

        .product-thumb,
        .product-info {
            display: table-cell;
            vertical-align: top;
        }

        .product-thumb {
            width: ($cart-thumb-size + 20);
            padding-right: 20px;

            >img {
                display: block;
                width: 100%;
            }

            @media screen and (max-width: 860px) {
                display: none;
            }
        }

        .product-info span {
            display: block;
            font-size: $font-size-xs;

            >em {
                font: {
                    weight: 500;
                    style: normal;
                }
            }
        }

        .product-title {
            margin-bottom: floor($grid-vertical-step * .25);
            padding-top: 5px;

            font: {
                size: $font-size-base;
                weight: normal;
            }

            >a {
                transition: color 0.3s;
                color: $product-title-color;
                line-height: $line-height-base;
                text-decoration: none;

                &:hover {
                    color: $nav-link-hover-color;
                }
            }

            small {
                display: inline;
                margin-left: 6px;
                font-size: 90%;
            }
        }
    }
}

.wishlist-table .product-item .product-thumb {
    display: table-cell !important;

    @media screen and (max-width: $screen-sm) {
        display: none !important;
    }
}

.shopping-cart-footer {
    display: table;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid $border-color;

    >.column {
        display: table-cell;
        padding: 5px 0;
        vertical-align: middle;

        &:last-child {
            text-align: right;

            .btn {
                margin: {
                    right: 0;
                    left: 15px;
                }
            }
        }

        @media (max-width: $screen-md) {
            display: block;
            width: 100%;

            &:last-child {
                text-align: center;
            }

            .btn {
                width: 100%;
                margin: floor($grid-vertical-step * .5) 0 !important;
            }
        }
    }
}

// Single Product
// -------------------------------------------------------

// Categories
.sp-categories {

    >a,
    i {
        display: inline-block;
        margin-right: 3px;
        color: $gray;
        font-size: $font-size-sm;
        text-decoration: none;
        vertical-align: middle;
    }

    >a {
        transition: color 0.25s;
    }

    >a:hover {
        color: $nav-link-hover-color;
    }
}

// Product Gallery
.product-gallery {
    position: relative;

    padding: {
        top: 40px;
        right: 15px;
        left: 15px;
    }

    border: 1px solid $border-color;
    border-radius: $border-radius-lg;

    .product-carousel {
        margin-bottom: 15px;
        text-align: center;
    }

    .gallery-item {
        >a {
            &::before {
                top: 50%;
                left: 50%;
                width: 70px;
                height: 70px;

                margin: {
                    top: -35px;
                    left: -35px;
                }

                border-radius: $border-radius-base;
            }

            &:hover::before {
                opacity: 0.7;
            }
        }
    }

    .product-badge {
        top: 15px;
        left: 15px;
        z-index: 10;
    }

    .product-thumbnails {
        display: block;
        margin: 0;

        margin: {
            right: -15px;
            left: -15px;
        }

        padding: 12px;
        border-top: 1px solid $border-color;
        background-color: $gray-lighter;
        list-style: none;
        text-align: center;

        >li {
            display: inline-block;
            margin: 10px 3px;

            >a {
                display: block;
                width: 94px;
                transition-property: border-color, opacity;
                transition-duration: 0.25s;
                border: 1px solid $border-color;
                border-radius: $border-radius-base;
                background-color: $white-color;
                opacity: 0.75;
                overflow: hidden;
            }

            &:hover>a {
                opacity: 1;
            }

            &.active>a {
                border-color: $brand-primary;
                cursor: default;
                opacity: 1;
            }
        }
    }

    .video-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;

        >a {
            width: 60px;
            height: 60px;
        }
    }
}

// Categroy Card
.category-card {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 30px 40px;
    border: 1px solid $border-color;
    background-color: $white-color;
    text-decoration: none;

    .category-card-info {
        margin: 0 auto;
        padding: 15px 0;
    }

    .category-card-title {
        margin-bottom: floor($grid-vertical-step * .5); //~12px
        color: $headings-color;
        font-size: $font-size-h4;
    }

    .category-card-subtitle {
        margin: 0;
        color: $gray;

        font: {
            size: $font-size-sm;
            weight: normal;
        }
    }

    .category-card-thumb {
        display: inline-block;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        overflow: hidden;

        >img {
            display: block;
            width: 100%;
            transition: transform 0.4s;
        }
    }

    &:hover .category-card-thumb>img {
        transform: scale(0.94);
    }

    @media (max-width: $screen-sm) {
        padding: 15px 25px;
    }
}

// IE10+ specific styles
@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .product-gallery .gallery-item>a {

        &::before,
        &::after {
            display: none !important;
        }
    }

    .product-gallery .video-btn {
        display: none !important;
    }

    .product-card .product-button-group {
        .product-button>i {
            transform: translateY(0) !important;
        }

        .product-button>span {
            display: none;
        }
    }
}

// Microsoft Edge specific styles
@supports (-ms-ime-align: auto) {
    .product-gallery .gallery-item>a {

        &::before,
        &::after {
            display: none !important;
        }
    }

    .product-gallery .video-btn {
        display: none !important;
    }

    .product-card .product-button-group {
        .product-button>i {
            transform: translateY(0) !important;
        }

        .product-button>span {
            display: none;
        }
    }
}

// Checkout (from template-1), modified to be accessible (steps in correct order, not reversed)
// -------------------------------------------------------

.checkout-steps {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: floor($grid-vertical-step * 1.7); // ~40px

    >.checkout-step {
        display: block;
        position: relative;
        width: 100%;

        border: {
            top: 1px solid $border-color;
            bottom: 1px solid $border-color;
        }

        color: $gray-dark;

        font: {
            size: $font-size-md;
            weight: 500;
        }

        line-height: 53px;

        text: {
            decoration: none;
            align: center;
        }

        >.step-text {
            display: inline-block;
        }

        >.angle {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -27px;
            width: 27px;
            z-index: 1;

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                border: solid transparent;
                content: "";
                pointer-events: none;
            }

            &::after {
                border-width: 26px;
                border-color: transparent;
                border-left-color: $body-bg;
            }

            &::before {
                margin-top: -0.5px;
                border-width: 27px;
                border-color: transparent;
                border-left-color: shade-color($border-color, 3%);
            }
        }

        &:not(.active) {
            transition: color 0.35s;

            &:hover {
                color: $primary;
            }

        }

        &.active {
            background-color: $nav-link-active-color;
            color: color-contrast($nav-link-active-color);

            >.angle::after {
                border-left-color: $nav-link-active-color;
            }
        }

        /* We include icons in the JS, so they can have tooltips
        &.completed::before {
            @include icon("check-circle");
            display: inline-block;
            margin: {
                top: -5px;
                right: 7px;
            }
            color: $brand-success;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            vertical-align: middle;
        }
        */
        &:last-child {
            border-right: 1px solid $border-color;
            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;

            .angle {
                display: none;
            }
        }

        &:first-child {
            border-left: 1px solid $border-color;
            border-top-left-radius: $border-radius-lg;
            border-bottom-left-radius: $border-radius-lg;
        }
    }

    @media (max-width: $screen-sm) {
        flex-wrap: wrap;

        >.checkout-step {
            width: 100%;
            margin-bottom: 10px;
            border: 1px solid $border-color;
            border-radius: $border-radius-lg;

            >.angle {
                display: none;
            }
        }
    }
}