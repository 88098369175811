// See also: components/widgets/FeaturedProductsEntry.scss


@mixin scrolling-cart-entries() {
    margin-left: floor($grid-vertical-step * 0.5) * -1;
    margin-right: floor($grid-vertical-step * 0.5) * -1;
    padding-left: floor($grid-vertical-step * 0.5);
    padding-right: floor($grid-vertical-step * 0.5);

    max-height: ($widget-entry-thumb-size + $grid-vertical-step) * 5.25;
    overflow-y: auto;
    position: relative;

    &>.scroll-shadow {
        display: block;
        position: sticky;
        left: 0;
        right: 0;
        height: 1px;
        margin-bottom: -1px;
        box-shadow: 0 0 8px 0 black;

        &.can-scroll-down {
            bottom: 0;
        }

        &.can-scroll-up {
            top: 0;
        }
    }
}

.cart-dropdown.widget-cart>.entries {
    @include scrolling-cart-entries();
}

@media (min-width: $screen-lg) {
    .widget-cart>.entries {
        @include scrolling-cart-entries();
    }
}

.widget-cart>.entries {
    >.entry {
        padding-top: floor($grid-vertical-step * 0.5);
        padding-bottom: floor($grid-vertical-step * 0.5);
        margin-bottom: 0;
        border-bottom: 1px dashed $border-color;

        .entry-modify {
            position: relative;
        }
    }
}

.hoverCartButton {
    margin: {
        left: 5px;
        bottom: 10px;
    }
}

.entry-meta {
    position: relative;

    .btn {
        position: relative;
        z-index: 0;
    }
}

.quantity-change {
    overflow-y: visible;
    color: $body-color;
    margin: 0;
    z-index: 1;

    @extend .inline-confirmation;

    .quantity-change-input {
        display: flex;

        .form-floating {
            margin-bottom: 0;
        }

        .form-control {
            height: 36px;
            width: calc($input-padding-x * 3 + 3em);
            margin-top: 4px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
