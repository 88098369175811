.address-modify-modal--default-row {
    text-align: center;
    // This is intended to be the same as .form-floating margin-bottom
    padding-top: floor($grid-vertical-step * 0.833);
    & > .form-check {
        display: inline-block;
    }
    &.address-modify-modal--default-row--my-default {
        color: $primary;
        font-weight: 500;
    }
}