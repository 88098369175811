.sure-preview--paperstock-modal {
    .modal-body {
        overflow-y: auto;
    }

    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    form {
        display: contents;
    }

    a {
        display: block;
        text-decoration: none;
        color: $black-color;
    }
}

.sure-preview--paperstock-row {
    cursor: pointer;
}

.sure-preview--paperstock-row-selected {
    @extend .sure-preview--paperstock-row;
    background-color: $gray-lighter;
}

.sure-preview--paperstock-color-swatch {
    width: 23px;
    height: 30px;
    border: 1px solid #ccc;
    background-color: white;

    &.sure-preview--paperstock-color-swatch-inline {
        display: inline-block;
        margin-right: 8px;
        line-height: normal;
    }

    &.sure-preview--paperstock-color-swatch-small {
        width: 1.2em;
        height: 1.2em;
    }
}

.finished-size--inputs.is-locked-aspect-ratio {
    .form-floating {
        input {
            border-color: $brand-primary;
        }
    }
}

.finished-size--section {
    background-color: $gray-lighter;

    margin: {
        bottom: 1rem;
    }

    padding: 1.5rem;

    ul {
        padding-left: 0;
        list-style: none;
    }

    .finished-size--current-paper {
        font-weight: bolder;
    }
}

.finished-size-modal {
    text-align: center;

    .width-input,
    .height-input {
        padding: {
            left: .25rem;
            right: .25rem;
        }

        .form-floating {
            margin: 0;
        }
    }

    .by-label {
        text-align: center;

        padding: {
            left: .25rem;
            right: .25rem;
        }
    }

    .buttons {
        text-align: right;

        padding: {
            left: .25rem;
            right: .25rem;
        }
    }
}