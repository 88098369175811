.alert {
    text-align: center;
    transition: opacity 0.15s linear;
    margin-bottom: 24px !important;

    >div>div {
        text-align: left;
    }

    >div>div:last-child {
        text-align: right;
    }

    >div>div:first-child {
        font-weight: 700;
        font-size: 28px;
        line-height: 1.25;
        margin-bottom: 0px;
    }

    .alert-title {
        font-size: 16px;
        font-weight: 700 !important;
    }

}

.order-action-buttons {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.order-information-title {
    @extend .h4;
}

.order-tracker {
    padding-bottom: 30px;
    margin-bottom: 0.25rem;

    a {
        text-decoration: none;
    }

    .card {
        margin-bottom: 1rem;
    }

    .title-bar {
        padding: 1.5rem;
        text-align: center;
        color: $white-color;
        font-size: 16px;
        background-color: $gray-darker;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        letter-spacing: .025em;
        text-transform: uppercase;
    }

    .status-bar {
        display: -ms-flexbox;
        display: flex;
        flex-wrap: nowrap;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        background-color: $gray-lighter;

        .status-detail {
            width: 100%;
            text-align: center;
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            .status-detail-title {
                margin-right: 5px;
                font-weight: 500;
            }

            div {
                margin: 8px 0 0 0;
            }
        }
    }

    .fulfillment-steps {
        display: -ms-flexbox;
        display: flex;
        flex-wrap: nowrap;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        .fulfillment-step {
            display: block;
            width: 100%;
            margin-bottom: 35px;
            text-align: center;

            .fulfillment-step-icon-wrap {
                display: block;
                position: relative;
                width: 100%;
                height: 80px;
                text-align: center;
            }

            .fulfillment-step-icon {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
                border: 1px solid $gray-light;
                border-radius: 50%;
                background-color: $gray-lighter;
                color: $gray-darker;
                font-size: 38px;
                line-height: 81px;
                z-index: 5;
            }

            @media (max-width: $screen-sm) {
                .fulfillment-step-icon {
                    width: 60px;
                    height: 60px;
                    font-size: 28px;
                    line-height: 61px;
                }

                .fulfillment-step-icon-wrap {
                    height: 60px;
                }
            }


            .fulfillment-step-icon-wrap::after {
                right: 0;
            }

            .fulfillment-step-icon-wrap::after,
            .fulfillment-step-icon-wrap::before {
                display: block;
                position: absolute;
                top: 50%;
                width: 50%;
                height: 3px;
                margin-top: -1px;
                background-color: $gray-light;
                content: "";
                z-index: 1;
                box-sizing: border-box;
            }

            .fulfillment-step-title {
                @extend .h4;
                margin-top: 16px;
                margin-bottom: 0;
                color: $gray;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .fulfillment-step.completed {
            .fulfillment-step-icon {
                border-color: $primary;
                background-color: $primary;
                color: $white-color;
            }

            .fulfillment-step-icon-wrap::after,
            .fulfillment-step-icon-wrap::before {
                background-color: $primary;
            }
        }

        .fulfillment-step.completed.warning {
            .fulfillment-step-icon {
                border-color: $danger;
                background-color: $danger;
                color: $white-color;
            }

            .fulfillment-step-icon-wrap::after,
            .fulfillment-step-icon-wrap::before {
                background-color: $danger;
            }
        }
    }
}

.order-tracker--ship-terms {
    font-size: $font-size-sm;

    button:hover {
        text-decoration: underline;
    }
}

.order-tracker--ship-terms--linklist{ 
    padding-top: '1em';
    text-align: 'center';
}

.order-tracker--ship-terms--linklist--button:hover {
    text-decoration: underline;
}

.order-tracker--ship-terms--linklist--button:not(:last-child):after{ 
    content: ", ";
    margin-right: 0.3em;
}

.order-tracker--ship-terms--linklist--button:first-of-type{ 
    margin-left: 0.3em;
}

.order-tracker--track-link:hover {
    text-decoration: underline;
}

.ordered-items-table {
    display: block;
    width: 100%;
    // max-height: 800px;
    // overflow-y: auto;
    // overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    margin-bottom: 20px;

    // @media (max-width: $screen-md) {
    //     max-height: 500px;
    // }

    table {
        margin-bottom: 0;
        width: 100%;
        color: $gray-darker;
        border-collapse: collapse;

        th {
            vertical-align: middle;
            padding-top: 17px;
            padding-bottom: 17px;
            border-width: 1px;
            font-weight: 500;
            border-color: $gray-light;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            border-top: 1px solid $gray-light;
            border-bottom: none;
            border-left: none;
            border-right: none;
            text-align: center;

            i {
                padding-right: 4px;
            }
        }

        th:first-of-type {
            text-align: left;
        }

        td {
            border-top: 1px solid $gray;
            padding: 0.75rem;
            border-color: $gray-light;
            vertical-align: middle;
            position: relative;

            .delete-confirmation {
                position: absolute;
                margin: 0;
                top: -6px;
                right: -4px;
                @extend .inline-confirmation;
                min-width: 230px;

                background: $white-color;
                box-shadow: 0 0 4px 1px rgba-var($black-color, 0.5);
                padding: 4px;
                border-radius: $border-radius-base;
                text-align: center;

                .delete-confirmation-prompt {
                    white-space: normal;
                }

                .btn {
                    margin-top: 4px;
                    margin-bottom: 0;

                    .formatted-string {
                        white-space: nowrap;
                    }
                }
            }
        }

        .product-delete a i {
            color: $danger;
        }

        td:nth-child(2),
        td:nth-child(3),
        td:last-child {
            text-align: center;
            font-size: 16px;

            .unit-price-button {
                font-size: 16px;
                text-decoration: underline;
            }

            .unit-price-button:hover {
                text-decoration: none;
            }
        }

        .auto-adjust-button-padding {
            margin-bottom: 0.5rem;
            margin-right: 0rem;
            display: block;
        }

        @media (max-width: $screen-md) {
            .auto-adjust-button-padding {
                margin-bottom: 0rem;
                margin-right: 0.5rem;
                display: inline;
            }
        }

        .product-item {
            // display: table;
            width: 100%;
            min-width: 150px;
            margin-top: 5px;
            margin-bottom: 3px;
        }

        .product-thumb {
            width: 130px;
            padding-right: 20px;
            display: table-cell;
            vertical-align: middle;

            &.thumb-shadow {

                img,
                svg {
                    box-shadow: 0 0 5px 1px rgba-var($black-color, 0.25);
                }
            }

            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
            }

            @media (max-width: $screen-sm) {
                display: block;
            }
        }

        .product-info {
            display: table-cell;
            vertical-align: top;

            .product-title {
                @extend .h4;
                margin-bottom: 6px;
                padding-top: 5px;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.3;
                word-break: break-word;

                button {
                    transition: color .3s;
                    color: $gray-darker;
                    line-height: 1.5;
                    white-space:normal;

                    i {
                        padding-left: 8px;
                        color: $primary;
                    }
                }

                button:hover {
                    color: $primary;
                    text-decoration: underline;
                }
            }

            ul {
                font-size: 12px;
                list-style: none;
                color: var(--c-muted);
                padding-left: 0px;

                li>.title {
                    font-weight: 500;
                    padding-right: 4px;
                    display: inline;
                    color: $gray-darker;
                }

            }
        }

    }
}

.ordered-items-item-summary-modal {
    .product-thumb {
        padding-left: 20px;
        display: table-cell;
        vertical-align: middle;

        &.thumb-shadow {

            img,
            svg {
                box-shadow: 0 0 5px 1px rgba-var($black-color, 0.25);
            }
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        @media (max-width: $screen-sm) {
            display: block;
        }
    }
}

.ordered-items-subtotal-footer {
    padding: 10px 0;
    border-top: 1px solid $gray-light;
    text-align: right;
    font-size: 1.2em;

    .subtotal-label {
        color: var(--c-muted);
        display: inline;
    }

    .subtotal {
        font-weight: 500;
        padding-left: 4px;
        display: inline;
    }
}

#approval-history {
    padding-bottom: 72px;
    margin-bottom: 0.5rem;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 20px;

    table {
        margin-bottom: 0;
        width: 100%;
        color: $gray-darker;
        border-collapse: collapse;

        th {
            vertical-align: middle;
            padding-top: 17px;
            padding-bottom: 17px;
            border-width: 1px;
            font-weight: 500;
            border-color: $gray-light;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            border-top: 1px solid $gray-light;
            border-bottom: none;
            border-left: none;
            border-right: none;
            text-align: left;
            background-color: $gray-lighter;
            color: $gray-dark;
        }

        td {
            border-top: 1px solid $gray;
            padding: 0.75rem;
            vertical-align: top;
            border-color: $gray-light;

            &.table-list-cell {
                vertical-align: middle;
            }
        }
    }
}

.item-proof-review .modal-body .buttons,
.payment-review .modal-body .buttons {
    text-align: center;
}

.price-breakdown--header {
    text-align: center;
}

.price-breakdown--view-label {
    display: inline-block;
    padding: 0 22px;
    margin-top: $btn-margin;
    margin-bottom: $btn-margin;
    line-height: ($btn-height - 2);
    height: $btn-height;
}

.price-breakdown--spaced-tables {
    >table {
        margin-bottom: 3rem;
    }
}

.orders--shipping-method-name-icon > i {
    padding-right: 4px;
}
.orders--multi-recipient--data-block {  
    padding-bottom: 1em; 
    
    > :first-child {
        font-weight: 500;
    }

    button {
        display: block;
    }

    .order-tracker--ship-terms {
        margin-top: 4px;
    }
}