@mixin centered-icon-container($imageSize, $iconSize: round($imageSize * 0.9)) {
    & > .icon {
        font-size: $iconSize;
    }
    min-width: $imageSize;
    min-height: $imageSize;
    display: flex;
    align-items: center;
    text-align: center;
}
