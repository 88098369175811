$primary: var(--c-primary);
$secondary: var(--c-secondary);
$success: var(--c-success);
$info: var(--c-info);
$warning: var(--c-warning);
$danger: var(--c-danger);

$brand-primary: $primary;
$brand-secondary: $secondary;
$brand-info: $info;
$brand-success: $success;
$brand-warning: $warning;
$brand-danger: $danger;

$focus-color: $primary;
$focus-color-light: tint-color($primary, 80%);
$focus-style: solid;
$focus-width: 1px;

$gray-darker: #232323;
$gray-dark: #505050;
// $gray doesn't have enough contrast on $gray-lighter, and $gray-dark is too dark to use for muted text
$gray-on-gray-lighter: #707070;
// Note: $gray is used as a foreground color on white backgrounds, #767676 is the lightest it can be while still being WCAG AA compliant
// #767676 is still light enough to also be used on black backgrounds, but it is not appropriate on non-white and non-black backgrounds
$gray: #767676;
$gray-light: #e5e5e5;
$gray-lighter: #f5f5f5;

$body-bg: $white-color;
$body-color: $gray-dark;

$code-color: $gray-dark;
$code-border-color: $gray-light;
$code-bg-color: $gray-lighter;

$component-active-bg: $primary;
$component-active-color: color-contrast($component-active-bg);

$dropdown-link-color: $gray-dark;
$dropdown-link-hover-bg: tint-color($primary, 80%);
$dropdown-link-hover-color: color-contrast($dropdown-link-hover-bg);

$toolbar-item-bg: $gray;