.sure-preview--header {
    font-size: $font-size-base;

    .topbar {
        border-bottom: 1px solid $border-color;

        >.toolbar {
            min-height: auto;

            .toolbar-item.hidden-on-mobile {
                width: 200px;
            }
        }
    }

    .sure-preview--title-region {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 18px 30px;
        display: flex;
        align-items: center;
        gap: 1rem;

        max-width: calc(100% - 96px);

        @media (max-width: $screen-xs) {
            max-width: calc(100% - 75px);
        }

        @media (min-width: ($nav-collapse + 1)) {
            max-width: calc(100% - 600px);
        }
    }
}