//
// Card
// --------------------------------------------------

.card-container {
    margin-bottom: 30px;
}

.card {
    border-radius: $border-radius-base;
    border-color: $border-color;

    .google-map {
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
    }
}

a.card {
    color: inherit;
    text-decoration: none;
}

.card.card-cart {
    border-color: $brand-primary;
}

.card.card-focused {
    background-color: $gray-lighter;

    .card-img-tiles>div:first-child:not(:only-child) {
        border-right: 1px solid $gray-light;
    }

    .card-img-tiles>div:nth-child(2) {
        border-bottom: 1px solid $gray-light;
    }
    small {
        // Needs to be darker text on grey background to be AA compliant
        color: $gray-on-gray-lighter;
    }
}

.card.card-list {
    text-align: center;
    height: 100%;

    .card-body {
        & :last-child {
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
    }

    strong {
        font-weight: 700;
    }
}

.card-header {
    border-bottom-color: $border-color;

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-bottom: 0;
    }

    &:first-child {
        border-radius: $border-radius-base $border-radius-base 0 0;
    }
}

.card-header,
.card-footer {
    background-color: $gray-lighter;
}

.card-footer {
    border-top-color: $border-color;

    &:last-child {
        border-radius: 0 0 $border-radius-base $border-radius-base;
    }
}

.card-body {

    p,
    ol,
    ul {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .card-mini-text {
        margin-bottom: 8px;
        color: $gray-dark;
        font-size: 85%;
    }

    .card-title {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
    }

    .card-description {
        color: $gray-dark;
    }

    .tab-content {
        padding: 0;
        border: 0;
    }
}

.card-button-group {
    text-align: center;
    padding-bottom: 20px;
}

// Background variants
.card.bg-primary {
    border-color: shade-color($brand-primary, 20%);

    .card-header {
        border-bottom-color: shade-color($brand-primary, 20%);
        background-color: shade-color($brand-primary, 10%);
    }
}

.card.bg-secondary,
.card.bg-faded {
    .card-header {
        background-color: shade-color($gray-lighter, 3%);
    }
}

.card.bg-success {
    border-color: shade-color($brand-success, 20%);

    .card-header {
        border-bottom-color: shade-color($brand-success, 20%);
        background-color: shade-color($brand-success, 10%);
    }
}

.card.bg-info {
    border-color: shade-color($brand-info, 20%);

    .card-header {
        border-bottom-color: shade-color($brand-info, 20%);
        background-color: shade-color($brand-info, 10%);
    }
}

.card.bg-warning {
    border-color: shade-color($brand-warning, 20%);

    .card-header {
        border-bottom-color: shade-color($brand-warning, 20%);
        background-color: shade-color($brand-warning, 10%);
    }
}

.card.bg-danger {
    border-color: shade-color($brand-danger, 20%);

    .card-header {
        border-bottom-color: shade-color($brand-danger, 20%);
        background-color: shade-color($brand-danger, 10%);
    }
}

.card.bg-dark {
    border-color: shade-color($gray-darker, 60%);

    .card-header {
        border-bottom-color: shade-color($gray-darker, 60%);
        background-color: shade-color($gray-darker, 40%);
    }
}

// Light skin
.text-white,
.text-light {
    .card-title {
        color: $white-color;
    }
}

// Card Group
.card-group .card {
    .card-footer {
        border-radius: 0;
    }

    &:first-child .card-footer {
        border-bottom-left-radius: $border-radius-base;
    }

    &:last-child .card-footer {
        border-bottom-right-radius: $border-radius-base;
    }
}

// Card Tiled Image
.card-img-tiles {
    display: block;
    border-bottom: 1px solid $border-color;

    >.inner {
        display: table;
        width: 100%;
    }

    .main-img,
    .thumblist {
        display: table-cell;
        width: 65%;
        padding: 15px;
        vertical-align: middle;

        >img {
            display: block;
            width: 100%;
            margin-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .thumblist {
        width: 35%;
        border-left: 1px solid $border-color;
    }

    .product-thumb img,
    .product-thumb svg {
        border-radius: 5px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
    }
}

// Card label
.card-label {
    display: inline-block;
    padding: 2px 4px;
    border: 1px solid $border-color;
    border-radius: $border-radius-xs;
    background-color: $gray-lighter;
    color: $gray-darker;
    vertical-align: middle;
}