//
// Site Header (Topbar + Navbar)
// --------------------------------------------------

.site-header {
    // Topbar and NavBar (stuck nav bar as scrolling down)
    .navbar,
    .topbar {
        background-color: $primary;

        .toolbar {
            .toolbar-item {

                >a,
                >button,
                >div:first-child {
                    color: color-contrast($primary);
                    font-weight: 600;

                    &:hover,
                    &:focus,
                    &.expanded {
                        background-color: $toolbar-item-bg;
                    }
                }

                >div:first-child[role="button"] {

                    &:hover,
                    &.expanded {
                        background-color: $toolbar-item-bg;
                    }
                }
            }
        }
    }

    // Navbar
    .navbar {
        position: relative;
        width: 100%;
        background-color: $gray-lighter;
    }

    // Site Branding / Logo
    .site-branding {
        background-color: $primary;
        /* The RSA logo background box */

        padding: {
            top: 5px;
            right: 30px;
            bottom: 5px;
            left: 30px;
        }

        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;

        .site-logo {
            display: block;
            color: $headings-color !important;
            text-decoration: none;

            >svg,
            >img {
                display: block;
                width: 100%;
            }
        }
    }

    // Search Box
    .search-box-wrap {
        width: 100%;
        padding: 18px 30px;

        .search-box-inner {
            width: 100%;
        }

        .search-box {
            width: 100%;
        }

        .categories-btn {
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;
            margin: 0;

            .btn {
                padding: 0 15px;

                border: {
                    right: 0;
                    top-right-radius: 0;
                    bottom-right-radius: 0;
                }
            }
        }

        .input-group {
            width: 100%;

            .form-control {
                border: {
                    top-left-radius: 0;
                    bottom-left-radius: 0;
                }
            }
        }
    }

    // Site Menu
    .site-menu {
        display: block;
        position: relative;
        width: 100%;
        z-index: 1;
        height: 45px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            >li>a {
                transition-property: background-color, border-color, color;
                transition-delay: 0.3s;
                color: $nav-link-color;
                text-decoration: none;
            }
        }

        >ul {
            display: table;
            margin: auto;

            >li {
                display: table-cell;
                position: relative;
                vertical-align: middle;

                >a {
                    display: block;
                    position: relative;
                    /* bolder menu items */
                    padding: 10px 52px;
                    /* vertical padding, and separation between, third-level menu items */

                    border: {
                        right: 1px solid transparent;
                        left: 1px solid transparent;
                    }

                    font: {
                        size: $nav-link-font-size;
                        weight: 500;
                    }

                    z-index: 5;

                    &::after {
                        display: none;
                    }

                    &::before {
                        display: block;
                        position: absolute;
                        top: -1px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        content: "";
                        transition: background-color 0.3s;
                        background-color: transparent;
                    }
                }

                .sub-menu {
                    @extend %sub-menu;
                }

                .mega-menu {
                    @extend %mega-menu;
                }

                &:hover {
                    >a {
                        color: $nav-link-hover-color;
                    }

                    >.mega-menu {
                        display: table;
                        animation: submenu-show 0.35s;
                    }
                }

                &.active>a {
                    color: $nav-link-active-color;

                    &::before {
                        background-color: $brand-secondary;
                        /* make the current menu option highlight into a gold bar (#faa635) */
                        height: 5px;
                    }
                }

                &.has-submenu:hover>a,
                &.has-megamenu:hover>a {
                    border-color: $border-color;
                    background-color: $white-color;

                    &::after {
                        display: block;
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background-color: $white-color;
                        content: "";
                        animation: submenu-show 0.25s;
                    }
                }

                &.has-megamenu {
                    position: static;
                }
            }
        }
    }
}

// Site and SurePreview Header shared styles
.sure-preview--header,
.site-header {
    position: relative;
    z-index: 500;
    padding: 0px;

    // Toolbar
    .toolbar {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
        min-height: 88px;

        .toolbar-item {
            position: relative;
            width: 96px;
            transition: background-color 0.3s;

            &.visible-on-mobile {
                display: none;
            }

            >a,
            >button,
            >div:first-child {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 5px;

                color: $nav-link-color;
                background: none;
                border: none;

                text: {
                    align: center;
                    decoration: none;
                }

                >div {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    transform: translateY(-50%);
                    text-align: center;

                    .header-icon i {
                        display: inline-block;
                        margin-bottom: 6px;
                        font-size: 25px;
                    }

                    >.text-label {
                        font-size: $font-size-xs;
                        padding-left: 0.25rem;
                        padding-right: 0.25rem;

                        @include ellipsis-lines(2);
                    }
                }
            }

            >a,
            >button,
            >div:first-child[role="button"] {

                &:hover,
                &.expanded {
                    background-color: $gray-lighter;
                }
            }
        }
    }
}

// Stuck Navbar
.navbar {

    .toolbar,
    .categories-btn {
        display: none;
    }
}

.navbar-stuck .navbar {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $primary;
    box-shadow: $navbar-stuck-shadow;
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    .site-menu {
        grid-column: 2;
    }

    .site-menu {
        ul {
            >li>a {
                color: color-contrast($primary);

                &:hover {
                    color: $secondary;
                }
            }
        }
    }

    .toolbar {
        white-space: nowrap;
        grid-column: 3;
        display: block;
        text-align: right;
        // position: absolute;
        // top: 0;
        // right: 0;
        // height: 100%;
        // z-index: 5;
        animation: toolbar-in 0.6s;

        // > .toolbar-inner {
        //     display: table;
        //     width: 100%;
        //     height: 100%;
        //     min-height: 100%;
        // }
        .toolbar-item {
            white-space: initial;
            display: inline-block;
            height: 100%;

            @media (max-width: $screen-xl) {
                width: 80px;
            }
        }
    }

    // .categories-btn {
    //     display: block;
    //     position: absolute;
    //     left: 30px;
    //     z-index: 5;
    //     animation: toolbar-in 0.6s;
    //     > .btn {
    //         padding: 0 15px;
    //     }
    //     @media (max-width: $screen-xl) {
    //         left: 15px;
    //     }
    // }
}

// Sub Menu Animation
@keyframes toolbar-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// Mobile Menu Container, not including the SurePreview header
.site-header {
    .topbar .mobile-menu {
        background-color: $primary;

        .toolbar {
            .toolbar-item {
                background-color: $primary;
            }
        }
    }
}

.topbar .mobile-menu {
    display: none;
    position: absolute;
    // At 100%, the position is just far enough down that a little white line leaks through the top of it
    top: 98%;
    left: 0;
    width: 100%;
    padding-top: 15px;
    background-color: $gray-lighter;
    box-shadow: $navbar-stuck-shadow;

    .mobile-search {
        padding: {
            right: 15px;
            bottom: 15px;
            left: 15px;
        }
    }

    .toolbar {
        display: table;
        width: 100%;

        .toolbar-item {
            display: table-cell;
            height: 75px;

            background-color: $white;
            vertical-align: middle;
        }
    }
}

// Slideable Menu (Mobile)
.slideable-menu {
    @extend %slideable-menu;
}

// Navbar Collapse
@media (max-width: $nav-collapse) {
    body {
        padding-top: 0 !important;
    }

    .navbar,
    .hidden-on-mobile {
        display: none !important;
    }

    .sure-preview--header,
    .site-header {
        .site-branding {
            border: 0;
        }

        .toolbar .toolbar-item.visible-on-mobile {
            display: block;
        }
    }

    .mobile-menu.open {
        display: block;
    }
}

@media (max-width: $screen-xs) {

    .sure-preview--header,
    .site-header {
        .toolbar .toolbar-item {
            width: 75px;
        }
    }
}