@mixin table-variant($state, $background) {
    @if type-of($background) == color {
        .table-#{$state} {
            $color: color-contrast(opaque($body-bg, $background));
            $hover-bg: mix($color, $background, percentage($table-hover-bg-factor));
            $striped-bg: mix($color, $background, percentage($table-striped-bg-factor));
            $active-bg: mix($color, $background, percentage($table-active-bg-factor));

            --#{$variable-prefix}table-bg: #{$background};
            --#{$variable-prefix}table-striped-bg: #{$striped-bg};
            --#{$variable-prefix}table-striped-color: #{color-contrast($striped-bg)};
            --#{$variable-prefix}table-active-bg: #{$active-bg};
            --#{$variable-prefix}table-active-color: #{color-contrast($active-bg)};
            --#{$variable-prefix}table-hover-bg: #{$hover-bg};
            --#{$variable-prefix}table-hover-color: #{color-contrast($hover-bg)};

            color: $color;
            border-color: mix($color, $background, percentage($table-border-factor));
        }
    }
    .table-#{$state} {
        // TODO JAS webcrd_dev-34 Fix mix to work with variables, or do something else to get this to work if we need it (maybe removing the caller of this mixin if we don't need it)
        $color: color-contrast(opaque($body-bg, $background));
        // $hover-bg: mix($color, $background, percentage($table-hover-bg-factor));
        // $striped-bg: mix($color, $background, percentage($table-striped-bg-factor));
        // $active-bg: mix($color, $background, percentage($table-active-bg-factor));

        --#{$variable-prefix}table-bg: #{$background};
        // --#{$variable-prefix}table-striped-bg: #{$striped-bg};
        // --#{$variable-prefix}table-striped-color: #{color-contrast($striped-bg)};
        // --#{$variable-prefix}table-active-bg: #{$active-bg};
        // --#{$variable-prefix}table-active-color: #{color-contrast($active-bg)};
        // --#{$variable-prefix}table-hover-bg: #{$hover-bg};
        // --#{$variable-prefix}table-hover-color: #{color-contrast($hover-bg)};

        color: $color;
        // border-color: mix($color, $background, percentage($table-border-factor));
    }
}
