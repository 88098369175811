.topbar .search-box-wrap {
    background-color: var(--c-primary);
    display: flex;

    .search-box-inner {
        align-self: center;

        .search-box {
            display: flex;
        }
    }
}