.register {
    .bottom-button-bar {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
    }
}

.register-instructions {
    @extend .h5;
}