.home-gallery-entry {
    margin-bottom: 30px;

    // When hovering the card, dim the image and show the button
    &:hover {
        & .home-gallery-entry-thumb-with-button {

            & img,
            & svg {
                opacity: 0.5;
            }

            & .home-gallery-entry-buttons {
                opacity: 1;
            }
        }
    }

    & .home-gallery-entry-thumb,
    & .home-gallery-entry-thumb-with-button {
        position: relative;
        // background-color: $gray-light;
        overflow: hidden;
        min-height: 75px;

        & img,
        & svg {
            display: block;
            width: auto;
            margin: auto;
            transition: opacity .35s;
        }

        & .home-gallery-entry-buttons {
            position: absolute;
            right: 20px;
            bottom: 20px;
            transition: opacity .35s;
            text-align: right;
            opacity: 0;
            z-index: 2;

            & .btn {
                margin: 0 0 0 7px;
                padding: 0 12px;
            }
        }
    }

    & .home-gallery-entry-details {
        display: grid;
        grid-template-columns: auto auto;
        padding-top: 24px;

        &> :only-child {
            grid-column: 1 / 3;
        }

        & .home-gallery-entry-title {
            margin-bottom: 0;
            padding-right: 5px;
            font-size: 16px;
            font-weight: 400;

            // We want the foreground color from parent instead of the Heading color
            color: inherit;
        }

        & .home-gallery-entry-meta {
            opacity: 0.60;
            text-align: right;
        }

        & .home-gallery-entry-center {
            @extend .h6;
            text-align: center;
        }

    }
}