.sure-preview--cover-modal-image {
    padding-right: 0;
}

.sure-preview--cover-modal-selector {
    padding-left: 0;
    >button {
        text-align: left;
        color: var(--bs-modal-color);
        border-color: var(--bs-gray-300);
        &:hover, &:focus{
            background-color: initial;
            color: inherit;
        }
        >i {
            position: absolute;
            font-size: 1.5em;
            right: 0.625rem;
            bottom: 0.4em;
        }
    }
    >label {
        top: 8px;
    }
}

.sure-preview--cover-modal-radio {
    display: inline-block;
    >label {
        font-size: initial;
        font-weight: initial;
        padding-left: 0.25em;
    }
}

.sure-preview--cover-modal-booklet-bw-guts-help {
    color: $gray-on-gray-lighter;
}