.top-header {
    padding: 0.5rem 1.75rem;
    color: color-contrast($primary);
    background-color: $primary;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 1.25rem;

    display: flex;
}

.top-header--left {
    @extend .hidden-md-down;
    margin-right: auto;
}

.top-header--right {
    font-size: 0.93em;
    flex-grow: 1;
    text-align: right;

    >button.btn-inline,
    >a {
        color: color-contrast($primary);
        display: inline-block;
        text-decoration: none;
        margin-left: 1.25rem;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            color: $secondary;
        }
    }
}

.top-header--contact-link {
    color: color-contrast($primary);
    transition: color 0.3s;
    opacity: 0.92;

    &:hover {
        color: $secondary;
    }
}