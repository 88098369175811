//
// Modal
// --------------------------------------------------

.modal {
    z-index: 100;
}

.modal-content, .modal-content>form {
    border-radius: $border-radius-lg;
    border-color: $border-color;
}

.modal-content>form {
    @extend .modal-content;
}

.modal-dialog-scrollable .modal-content,
.modal-dialog-scrollable .modal-content > form {
    height: 100%;
}

.modal-header,
.modal-body,
.modal-footer {
    padding: {
        right: 20px;
        left: 20px;
    }
}

.modal-header {
    background-color: $gray-lighter;
}

.modal-body {
    word-break: break-word;
}

.modal-footer {
    padding: {
        top: floor($grid-vertical-step * .5);
        bottom: floor($grid-vertical-step * .5);
    }

    // .btn {
    //     margin: {
    //         right: 0;
    //         left: 12px;
    //     }
    // }
}

.scroll-disabled .navbar-stuck .navbar {
    width: calc(100% - var(--scrollbar-width));
}

.scroll-disabled .scroll-to-top-btn {
    display: none !important;
}

.modal-backdrop {
    z-index: 0;
}