.home-panel.home-banner {
    @include padding-top(1);
    @include padding-bottom(1);
}

.login-panel,
.home-panel {
    padding: {
        bottom: 42px;
    }

    @include padding-top(2);

    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
        width: 100%;
    }

    color: $gray-darker;

    &>.panel-title {
        font-weight: 500;
        color: $gray-on-gray-lighter;
    }
}

.home-panel {
    & h1 {
        text-align: center;
    }
}

.login-panel,
.home-panel,
.login-panel .container,
.home-panel .container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-panel--border-top,
.home-panel--border-top {
    border-top: 3px solid #000000;
}

.login-panel--border-bottom,
.home-panel--border-bottom {
    border-bottom: 3px solid #000000;
}

.login-panel--shadow-top,
.home-panel--shadow-top {
    box-shadow: inset 0px 14px 30px -6px rgba(0, 0, 0, 0.15)
}

.login-panel--shadow-bottom,
.home-panel--shadow-bottom {
    box-shadow: inset 0px -14px 30px -6px rgb(0 0 0 / 15%);
}

// Since box-shadow is a shared property for both CSS rules they will overwrite each other if you had both top and bottom
.login-panel--shadow-top.login-panel--shadow-bottom,
.home-panel--shadow-top.home-panel--shadow-bottom {
    box-shadow: inset 0px 14px 30px -6px rgba(0, 0, 0, 0.15), inset 0px -14px 30px -6px rgb(0 0 0 / 15%);
}

.bottom-button {
    text-align: center;
}

.panel-title {
    // The title needs to be <h1> for accessibility, but we want it styled like a smaller <h3>
    @extend .h3;
    margin-bottom: 30px;

    font-weight: 500;
    color: $gray-on-gray-lighter;
}