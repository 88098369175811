.cart--account-code-section,
.myAccount--account-code-section {

    .account-code-type-card {
        background-color: $gray-lighter;
        border-bottom: 1px solid #e5e5e5;
        border-radius: 5px;
        margin-bottom: 1.5rem;

        .account-code-type-card-header {
            background-color: shade-color($gray-lighter, 3%);
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);

            &:hover {
                background-color: $gray-lighter;
            }

            .account-code-type-card-header-text {
                display: block;
                position: relative;
                padding: .85rem 1.25rem;
                color: #232323;
                font-size: 16px;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .account-code-type-card-body {
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            padding: 1.25rem;

            .row>* {
                padding-left: .25rem;
                padding-right: .25rem;
            }

            .account-code-input {
                padding-left: .25rem;
                padding-right: .25rem;

                &.matches {
                    .state-icon {
                        color: $brand-success;
                    }
                }

                &.no-matches {
                    .state-icon {
                        color: $brand-danger;
                    }
                }
            }

            .account-code-type-helptext {
                font-style: italic;
                color: $gray-on-gray-lighter;
                margin-bottom: 1.25rem;
            }

            @media(max-width: $screen-sm ) {
                .account-code-type-lookup-button {
                    .btn {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .account-code-type-add-more {
                color: $gray-on-gray-lighter;
                -ms-flex-align: center;
                align-items: center;
                -ms-flex-pack: end;
                justify-content: flex-end;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                display: -ms-flexbox;
                display: flex;

                button {
                    margin: {
                        left: .5rem;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
    }

    margin-bottom: 30px;
}