$radio-checkbox-size: $form-check-input-width;
$checkbox-check-size: $form-check-input-width * 0.9;
$switch-dot-size: $form-check-input-width * 0.625;
$switch-dot-padding: $form-check-input-width * 0.125;

input[type="checkbox" i],
input[type="radio" i] {
    background-image: none !important;
    width: $radio-checkbox-size;
    height: $radio-checkbox-size;
    appearance: none;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    border: 1px solid $gray;
    background-color: $gray-lighter;

    &:disabled {
        cursor: not-allowed;
        background-color: $gray-light;
    }

    &:focus {
        border-color: $focus-color;
    }

    &::before,
    &::after {
        position: absolute;
        display: block;
    }
 
    &:checked {
        background-color: $primary;
        border-color: $primary;
        &:disabled {
            background-color: tint-color($primary, 70%);
            border-color: tint-color($primary, 70%);
        }

        &:focus {
            &::before {
                content: "";
                border: $focus-width $focus-style $focus-color;
                border-radius: inherit;
                top: -1 * $focus-width - 2px;
                left: -1 * $focus-width - 2px;
                bottom: -1 * $focus-width - 2px;
                right: -1 * $focus-width - 2px;
            }
            &:not(:focus-visible)::before {
                content: none;
            }
        }
    }
}

input[type="checkbox" i]:not([role="switch" i]) {
    border-radius: 2px;
    &:checked::after {
        color: color-contrast($primary);
        @include icon('check');
        font-size: $checkbox-check-size;
        line-height: $checkbox-check-size;
        font-weight: bold;
        text-align: center;
        top: 0;
        left: 0;
        right: 0;
    }
}

input[type="radio" i] {
    border-radius: 50%;

    &:checked::after {
        content: "";
        top: 21.5%;
        left: 21.5%;
        background-color: color-contrast($primary);
        width: 57.2%;
        height: 57.2%;
        border-radius: 50%;
    }
}


.form-switch .form-check-input {
    background-image: none !important;

    &::after {
        content: "";
        top: calc(50% - #{$switch-dot-size * 0.5});
        left: $switch-dot-padding;
        background-color: $gray-dark;
        width: $switch-dot-size;
        height: $switch-dot-size;
        border-radius: 50%;
        @include transition(left .15s ease-in-out);
    }
    &:checked::after {
        // The -0.00225em is to fix sub-pixel rounding oddities
        left: calc(100% - #{$switch-dot-size + $switch-dot-padding - 0.00225em});
        background-color: color-contrast($primary);
    }
}