.sure-preview--thumbnail-outer-container {
    width: 110px;
    height: 110px;
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sure-preview--thumbnail-container {
    position: relative;
    line-height: 0;
}

.sure-preview--thumbnail--font-substitution {
    position: absolute;
    left: -11px;
    bottom: -11px;
    width: 22px;
}

.sure-preview--thumbnail--exception {
    position: absolute;
    right: -11px;
    bottom: -11px;
    width: 22px;
}

.sure-preview--thumbnail--bookmark {
    position: absolute;
    right: 7px;
    top: -2px;
}

.sure-preview--thumbnail--tab {
    position: absolute;
    top: 0;
    right: -20px;
    width: 20px;
}

.sure-preview--thumbnail {
    max-height: 110px;
    max-width: 110px;
    box-shadow: $item-thumbnail-shadow;
    background-color: $white-color;
    &.placeholder {
        height: 110px;
        width: 85px;
        display: inline-block;
    }
}

.sure-preview--grid-view--entry.selected .sure-preview--thumbnail {
    box-shadow: $item-thumbnail-shadow, 0 0 10px 2px #fff;
}