.skeleton-progress-bar {
    height: 1.25em;
    width: 16em;
    max-width: 100%;
    border: 1px solid $primary;
    border-radius: $border-radius-sm;
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: text-bottom;
    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -26.10815px;
        $pbColor1: tint-color($primary,50%);
        $pbColor2: tint-color($primary,80%);
        background: repeating-linear-gradient(-50deg, $pbColor1, $pbColor1 10px, $pbColor2 12px, $pbColor2 18px, $pbColor1 20px);
        animation: skeleton-progress-bar-gradient 0.75s linear infinite;
    }
}

@keyframes skeleton-progress-bar-gradient {
    from {
        transform: translateX(0px);
    }
    to { 
        transform: translateX(26.10815px);
    }
}

.cart-status-entry {
    display: grid;
    grid-template-columns: $widget-entry-thumb-size auto max-content;
    padding: $grid-vertical-step * 0.5 0;
    &:not(:last-child) {
        border-bottom: 1px dashed $border-color; 
    }

    .cart-status-entry--thumbnail {
        text-align: center;
        height: 100%;
        max-height: $widget-entry-thumb-size;
        display: grid;
        align-items: center;
        img, svg {
            margin: auto;
            max-width: $widget-entry-thumb-size;
            max-height: $widget-entry-thumb-size;
            border-radius: $border-radius-base;
            overflow: hidden;
            box-shadow: $item-thumbnail-shadow;
        }
    }

    .cart-status-entry--details {
        word-break: break-word;
        padding: 0 12px;

        .cart-status-entry--item-name {
            margin-bottom: 0;
            font-size: $widget-link-font-size;
            > [role="button"] {
                transition: color 0.3s;
                color: $nav-link-color;
                font-weight: 400;
                text-decoration: none;
                &:hover {
                    color: $nav-link-hover-color;
                }
            }
        }

        .cart-status-entry--status {
            display: block;
            margin-bottom: 0;
            padding-top: 4px;
            color: $widget-meta-color;
            font-size: $font-size-sm;
        }
    }

    &.status-error .cart-status-entry--status {
        color: $danger;
    }
    .cart-status-entry--actions {
        white-space: nowrap;
        position: relative;
        :not(:first-child) {
            margin-left: 4px;
        }

        .delete-confirmation {
            position: absolute;
            margin: 0;
            top: -6px;
            right: -4px;

            background: $white-color;
            box-shadow: 0 0 4px 1px rgba-var($black-color, 0.5);
            padding: 4px;
            border-radius: $border-radius-base;
            text-align: center;

            .delete-confirmation-prompt {
                white-space: normal;
            }
            .btn {
                margin-top: 4px;
                margin-bottom: 0;
                .formatted-string {
                    white-space: nowrap;
                }
            }
        }
    }
}