.home-banner {
    background-color: $primary;

    & .card {
        background: none;
        border: 0;

        & .card-body {
            display: flex;
            flex-direction: row;
            align-items: center;

            & .home-banner-image {

                & img,
                & svg {
                    width: 100%;
                }
            }

            & .home-banner-text {
                @extend .h2;
                padding-left: .5rem;

                margin: {
                    bottom: 0px;
                }

                // We want the foreground color from parent instead of the Heading color
                color: inherit;
            }
        }
    }
}