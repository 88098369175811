.checkout-cost-summary {
    color: $gray-darker;
    margin-bottom: 1rem;

    .cost-summary-row {
        display: grid;
        grid-template-columns: auto min-content;

        &:not(:first-child) {
            margin-top: $grid-vertical-step * .5;
        }

        &:not(:last-child) {
            margin-bottom: $grid-vertical-step * .5;
        }

        .cost-value {
            white-space: nowrap;
            text-align: right;
            padding-left: 1em;
        }
    }

    .cost-summary-row:last-child {
        padding-top: $grid-vertical-step * .5;
        border-top: 1px solid $gray-light;
        font-size: $font-size-base;
    }
}