.address--address-block {
    line-height: 1.8;

    & > :first-child {
        font-weight: bold;
    }

    & > div > span {
        display: inline-block;
        word-break: break-word;
    }
}
