.cart-dropdown {
    > .widget-cart {
        margin-bottom: floor($grid-vertical-step * .5);
    }

    .cart-dropdown-total {
        text-align: right;
        color: $gray-darker;
        padding-top: $spacer * 0.5;
        padding-bottom: $spacer * 0.5;
        & > :first-child {
            color: $gray;
        }
    }
    .btn-block:last-child {
        margin-bottom: 0;
    }
}
