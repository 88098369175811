.sure-preview-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-columns: max-content auto;
}

.sure-preview {
    display: flex;
    flex-direction: column;
    
    .sure-preview--body {
        height: 100%;
        max-width: 100%;
        overflow: hidden;
        & > .row {
            height: 100%;
        }
    }
}