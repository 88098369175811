.item-details__more-info {
    margin-left: 5px;
}

.item-details__continue-button {
    margin: 0;
}

.item-details__trash-button {
    margin: 0;
    width: min-content;
}


.item-details__expiration-line {
    padding-top: .25rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    display: flex;
}

.item-details__deletion-line {
    padding-top: .25rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    display: flex;
}

.item-details__expire-warning {
    margin-left: 1rem;
    color: $brand-danger;
    text-decoration: none;
}

.item-details__small-media-section-spacer {
    padding-top: 48px;
    margin-top: .5rem;
}

.item-details__name {
    margin-bottom: .25rem;
    word-break: break-word;
}

.item-details__price {
    @extend .h3;
    display: block;
    margin-bottom: 1rem;

    .multiple-prices {
        color: $nav-link-active-color;
    }
}

.item-details__controls {
    padding-bottom: 1.5rem;
    align-items: flex-end;
}

.item-details__quantity-container {
    .form-floating {
        margin-bottom: 0px;
    }
}

.item-details__small-media-button-spacer {
    padding-top: 1.5rem;
}

.item-details__top-section {
    padding-bottom: 72px;
}

.item-details__bottom-section {
    padding-bottom: 48px;
    padding-top: 72px;
    margin-bottom: 1rem;
    @extend .bg-secondary;

    .ordered-items-table {
        padding-top: 48px;
    }
}

.item-details__brief-instructions {
    @extend .text-muted;
}

.item-details__form-number {
    color: $gray;
    padding-top: 3px;
    display: block;
    font-size: 70%;
}

.item-details__back-button {
    display: block;
    position: fixed;
    top: 30%;
    padding: 15px;
    margin-top: -23px;
    background-color: $white-color;
    color: $gray-dark;
    font-size: 20px;
    text-align: center;
    box-shadow: 0 3px 10px 0 rgb(0 0 0/18%);
    cursor: pointer;
    z-index: 9900;
    left: 0;
    border-top: 1px solid $gray-light;
    border-right: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-decoration: none;

    .back-text {
        display: block;

        @media (max-width: $screen-xl) {
            display: none;
        }
    }
}

#full-instructions {
    padding-bottom: 2rem;
}

.item-details__extended-information {
    h3 {
        @extend .h4;
    }

    ul {
        margin-bottom: 1.5rem;
        padding-left: 0;
        list-style: none;
    }
}

div.item-details__finishing-options {
    max-width: 500px;
    h6 {
        margin-top: 20px;
    }
    ul {
        margin-bottom: 0px;
    }
    table {
        padding-top: 5px;
        background-color: #fff;

    }
    thead {
        background-color: #fff !important;
    }
    button {
        text-decoration: underline;
    }
    button:hover {
        text-decoration: none;
    }
}

li.item-details__simple-instructions {
    padding-left: 3ch;
    font-style: italic;
}

.other-finishing-detail {
    font-weight: 500;
}

.quantity-btn {
    text-decoration: underline;
    font-weight: 500;
}

.item_details--print-block {
    margin: {
        bottom: 30px;
    }
}