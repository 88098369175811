.item-cost-breakdown-modal {

    .item-cost-breakdown--body > hr {
        @include padding-bottom(0.5);
    }
    
    .item-cost-breakdown--category-option {
        &[scope="row"] {
            & > span {
                white-space: nowrap;
                display: inline-block;
                & .formatted-string {
                    white-space: pre;
                }
                &:not(:last-child)::after {
                    content: ', ';
                    white-space: pre;
                }
            }
        }
    }
    
    td.item-cost-breakdown--quantity .formatted-string {
        white-space: pre;
    }
    
    .item-cost-breakdown--unit-price {
        text-align: center;
    }
    
    .item-cost-breakdown--option-total,
    .item-cost-breakdown--shipping-total,
    .item-cost-breakdown--item-total,
    .item-cost-breakdown--recipient-total,
    .item-cost-breakdown--extended-total,
    .item-cost-breakdown--unit-price,
    .item-cost-breakdown--quantity {
        text-align: right;
    }
    
    td.item-cost-breakdown--option-total {
        vertical-align: bottom;
    }
    
    td.item-cost-breakdown--order-document-total,
    td.item-cost-breakdown--order-recipient-total {
        vertical-align: bottom;
        text-align: right;
    }
    
    th>.item-cost-breakdown--order-document-name-details{
        font-weight: normal;
    }
    
    .item-cost-breakdown--order-document-name
    .item-cost-breakdown--order-recipient-name
    .item-cost-breakdown--recipient-total
    .item-cost-breakdown--category-option {
        word-wrap: anywhere;
    }
}

.item-cost-breakdown-modal-summary {
    td.item-cost-breakdown--item-total {
        border-bottom: none;
        padding: ($table-cell-padding-y * 2) $table-cell-padding-x;
        text-align: right;
        & > strong {
            margin-left: 1.0rem;
        }
    }
}

.original-cost {
    color: $brand-danger;
    text-decoration: line-through;
    margin-right: 10px;
}

.tax-percent {
    margin-left: 5px;
}

.bw-overrides.list-group {
    display: inline-block;
    
    .list-group-item {

        &.header {
            font-weight: bold;
        }
        
        padding: {
            top: 2px;
            bottom: 2px;
        }
    }
}