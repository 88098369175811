//
// Alert
// --------------------------------------------------

// Base class
.alert {
    $alert-border-padding: 6px;
    display: block;
    position: relative;
    padding: $grid-vertical-step * 0.667;
    margin: $alert-border-padding;
    border: 5px solid transparent;
    border-radius: $border-radius-base;

    background: {
        position: center;
        repeat: no-repeat;
        size: cover;
        clip: padding-box;
    }

    i {
        display: inline-block;
        font-size: 1.2em;
        vertical-align: middle;
    }

    >*,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    a {
        &:last-child:not(.btn) {
            margin: 0;
        }
    }

    &::before {
        display: block;
        position: absolute;
        top: -$alert-border-padding;
        right: -$alert-border-padding;
        bottom: -$alert-border-padding;
        left: -$alert-border-padding;
        border: 1px solid transparent;
        border-radius: $border-radius-base;
        content: "";
        pointer-events: none;
    }

    // Alert Dismissible
    .alert-dismissible {
        transition: opacity 0.4s;

        &.fade.show {
            opacity: 1;
        }
    }

    .alert-close {
        display: block;
        position: absolute;
        top: 10px;
        right: 12px;

        font: {
            size: $font-size-base;
        }

        cursor: pointer;

        &::before {
            @include icon("x");
        }
    }
}

a.alert {
    text-decoration: none;
}

// Color Variants
.alert-default {
    //@include alert-variant($gray-dark, rgba-var($gray-dark, 0.1), rgba-var($gray-dark, 0.25));
    @include alert-variant-rsa($gray-dark);
}

.alert-primary {
    // @include alert-variant($brand-primary, rgba-var($brand-primary, 0.1), rgba-var($brand-primary, 0.25));
    @include alert-variant-rsa($brand-primary);
}

.alert-secondary {
    // @include alert-variant($brand-primary, rgba-var($brand-primary, 0.1), rgba-var($brand-primary, 0.25));
    @include alert-variant-rsa($brand-secondary);
}

.alert-info {
    // @include alert-variant($brand-info, rgba-var($brand-info, 0.1), rgba-var($brand-info, 0.25));
    @include alert-variant-rsa($brand-info);
}

.alert-success {
    // @include alert-variant($brand-success, rgba-var($brand-success, 0.1), rgba-var($brand-success, 0.25));
    @include alert-variant-rsa($brand-success);
}

.alert-warning {
    // @include alert-variant($brand-warning, rgba-var($brand-warning, 0.1), rgba-var($brand-warning, 0.25));
    @include alert-variant-rsa($brand-warning);
}

.alert-danger {
    // @include alert-variant($brand-danger, rgba-var($brand-danger, 0.1), rgba-var($brand-danger, 0.25));
    @include alert-variant-rsa($brand-danger);
}

// With Background Image
.alert-image-bg {
    border: 0;

    &::before {
        display: none;
    }
}

// This is not (currently) used, but can be re-included if we start to use elements from these components
// Toast Notifications
// .iziToast {
//     border: 1px solid $border-color;
//     background: $gray-lighter;
//     &::after {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         border-radius: 4px;
//         box-shadow: 0 8px 10px -4px rgba-var($black-color, 0.2);
//         content: "";
//         z-index: -1;
//     }
//     > .iziToast-body {
//         margin-left: 15px;
//     }
//     > .iziToast-close {
//         color: inherit;
//         transition: opacity 0.25s;
//         background: none;
//         &::before {
//             @include icon("x");
//         }
//     }
//     &.iziToast-info {
//         border-color: rgba-var($brand-info, 0.3);
//         background-color: tint-color($brand-info, 90%);
//         color: shade-color($brand-info, 40%);
//     }
//     &.iziToast-success {
//         border-color: rgba-var($brand-success, 0.3);
//         background-color: tint-color($brand-success, 90%);
//         color: shade-color($brand-success, 40%);
//     }
//     &.iziToast-warning {
//         border-color: rgba-var($brand-warning, 0.3);
//         background-color: tint-color($brand-warning, 90%);
//         color: shade-color($brand-warning, 40%);
//     }
//     &.iziToast-danger {
//         border-color: rgba-var($brand-danger, 0.3);
//         background-color: tint-color($brand-danger, 90%);
//         color: shade-color($brand-danger, 40%);
//     }
// }
// .toast.position-fixed {
//     min-width: 300px;
//     z-index: 9000;
//     &.top-center {
//         top: 30px;
//         left: 50%;
//         transform: translateX(-50%);
//     }
//     &.top-right {
//         top: 30px;
//         right: 30px;
//     }
//     &.top-left {
//         top: 30px;
//         left: 30px;
//     }
//     &.bottom-center {
//         bottom: 30px;
//         left: 50%;
//         transform: translateX(-50%);
//     }
//     &.bottom-right {
//         bottom: 30px;
//         right: 30px;
//     }
//     &.bottom-left {
//         bottom: 30px;
//         left: 30px;
//     }
//     @media (max-width: $screen-xs) {
//         &.top-right,
//         &.top-left,
//         &.bottom-right,
//         &.bottom-left {
//             right: auto;
//             left: 50%;
//             transform: translateX(-50%);
//         }
//     }
// }