.home-horizontal-card-entry.card {
    background-color: $gray-lighter;
    border: 0;
    margin-bottom: 30px;

    & .card-body {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(min-content, 50%) auto;
        gap: 1rem;
        align-items: center;

        & .entry-image {

            &:hover {
                opacity: 0.5;
            }

            & img,
            & svg {
                border-radius: $border-radius-base;
                display: block;
            }
        }

        & .entry-content {
            & .entry-text {
                @extend .h6;
                font-weight: 300;
            }
        }
    }
}