$font-sans-serif: var(--font-family);
$font-family-base: $font-sans-serif;
$font-family-headings: $font-sans-serif;

$font-family-monospace: monospace;
$font-family-code: $font-family-monospace;

// Font sizes
$font-size-base: 16px;
$font-size-lead: floor(($font-size-base * 1.125)); // ~18px
$font-size-md: floor(($font-size-base * 0.875)); // ~14px
$font-size-sm: floor(($font-size-base * 0.8125)); // ~13px
$font-size-xs: floor(($font-size-base * 0.75)); // ~12px

// Headings
$font-size-h1: floor(($font-size-base * 2.25)); // ~36px
$font-size-h2: floor(($font-size-base * 2)); // ~32px
$font-size-h3: floor(($font-size-base * 1.75)); // ~28px
$font-size-h4: floor(($font-size-base * 1.5)); // ~24px
$font-size-h5: floor(($font-size-base * 1.25)); // ~20px
$font-size-h6: floor(($font-size-base * 1.125)); // ~18px
$font-size-h7: floor(($font-size-base * 1.075)); // ~17px

// Display headings
$display-1: floor(($font-size-base * 4.5)); // ~72px
$display-2: floor(($font-size-base * 3.75)); // ~60px
$display-3: floor(($font-size-base * 3.125)); // ~50px
$display-4: floor(($font-size-base * 2.5)); // ~40px
$display-404: floor(($font-size-base * 15)); // ~240px