.sure-preview--document-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: $grid-gutter-width * -0.5;
    margin-left: $grid-gutter-width * -0.5;

    .sure-preview--no-preview-message {
        // $btn-height is the size of the toolbox toggle - we don't want the toggle to hide the text
        padding: 0.5rem calc($btn-height + 0.5rem);
        font-size: $font-size-lead;
    }
    .sure-preview--document-preview--debug {
        position: absolute;
        top: 0;
        left: 0;
        text-align: left;
        opacity: 0.75;
        padding: 0.5em 1em;
        background-color: #000;
        color: #fff;
        border: 1px solid #fff;
        max-width: 100%;
        font-family: monospace;
        & > div + div {
            margin-top: 0.5em;
        }
    }
    canvas {
        align-self: flex-start;
    }
}
