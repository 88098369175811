.step-title {
    color: $gray-darker;
}

.checkout-step > .icon-tooltip-container .icon {
    display: inline-block;
    margin: {
        top: -5px;
        right: 7px;
    }
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
}