.sure-preview--pictogram--group {
    & + .sure-preview--pictogram--group {
        @include margin-top(1);
    }

    .sure-preview--pictogram--group--title {
        border-bottom: 1px solid $gray-light;
        font-weight: 500;
    }

    .sure-preview--pictogram--option {
        display: inline-grid;
        gap: 6px;
        padding: 8px;
        margin: 1px;
        grid-template-rows: minmax(85px, max-content);
        min-width: 101px /* 85 + 8 * 2 */;
        text-align: center;
        vertical-align: top;
        align-items: center;

        &.selected {
            $optionBGColor: tint-color($brand-primary, 80%);
            background-color: $optionBGColor;
            color: color-contrast($optionBGColor);
        }
        &:hover {
            background-color: $brand-primary;
            color: color-contrast($brand-primary);
        }
        .sure-preview--pictogram--option--image .icon {
            font-size: 60px;
        }
        .sure-preview--pictogram--option--name {
            line-height: normal;
            font-size: 0.9em;
            font-weight: 600;
        }
    }
}
