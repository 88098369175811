@mixin caret-down {
    @include icon-content("chevron-down");
}

@mixin caret-up {
    @include icon-content("chevron-up");
}

@mixin caret-end {
    @include icon-content("chevron-right");
}

@mixin caret-start {
    @include icon-content("chevron-left");
}

@mixin caret-base {
    display: inline-block;
    vertical-align: $caret-vertical-align;
    margin-top: -1px;

    @include icon-font();
}

@mixin caret($direction: down) {
    @if $enable-caret {
        @if $direction == start {
            &::before {
                margin-right: $caret-spacing;
                @include caret-base();
                @include caret-start();
            }
        } @else {
            &::after {
                margin-left: $caret-spacing;
                @include caret-base();
                @if $direction == down {
                    @include caret-down();
                } @else if $direction == up {
                    @include caret-up();
                } @else if $direction == end {
                    @include caret-end();
                }
            }

            &:empty::after {
                margin-left: 0;
            }
        }
    }
}
