.save-state {
    display: inline-grid;
    grid-template-areas: 'a';
    align-items: baseline;

    & > * {
        visibility: hidden;
        grid-area: a;
    }
    .save-state--current {
        visibility: visible;
    }
}