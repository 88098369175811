// Note: `_baseline` is intentionally omitted here - it needs to be loaded before functions that other variables rely on are used
// Note: These are intentionally not alphabetized - some of these files depend on variables defined in earlier files
@import "broken-variables.scss";
@import "colors.scss";
@import "feather-icons-map.scss";
@import "pe-icon-7-stroke-map.scss";
@import "fonts.scss";
@import "borders.scss";
@import "forms.scss";
@import "myAccount.scss";

$container-max-widths: (
//   sm: 100%,
//   md: 100%,
//   lg: 100%,
  xl: 1170px,
//   xxl: 1320px
) !default;

$caret-vertical-align: middle;
$caret-spacing: 0.3em;

$grid-gutter-width: 2rem;

$hr-opacity: 1;

$widget-entry-thumb-size: 72px;

$list-group-border-radius: 7px;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

$zindex-tooltip: 9500;

$disabled-button-opacity: 0.4;