//
// Widgets (Blog & Shop)
// --------------------------------------------------

// Sidebar
.sidebar {
    width: 100%;
}

.sidebar-toggle,
.sidebar-background,
.sidebar-close {
    display: none;
}

// Sidebar Off-Canvas
@media (max-width: $screen-lg) {
    .sidebar-offcanvas {
        position: fixed;
        top: 0;
        width: $sidebar-offcanvas-width;
        height: 100%;
        padding: 42px $grid-vertical-step 30px;
        background-color: $white-color;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
        z-index: 1010;
        overflow-y: auto;
        visibility: hidden;
        // -ms-overflow-style: none;

        $sidebar-offcanvas-transition-timing: 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s;
        $sidebar-offcanvas-transition: right $sidebar-offcanvas-transition-timing,
        left $sidebar-offcanvas-transition-timing,
        box-shadow 0.3s 0.3s;
        transition: $sidebar-offcanvas-transition, visibility 0.4s 0.4s;

        &.open {
            transition: $sidebar-offcanvas-transition;
        }

        &.position-right {
            right: -($sidebar-offcanvas-width);
        }

        &.position-left {
            left: -($sidebar-offcanvas-width);
        }

        &.open {
            box-shadow: 0 0 50px 3px rgba(0, 0, 0, 0.15);
            visibility: visible;

            &.position-right {
                right: 0;
            }

            &.position-left {
                left: 0;
            }
        }
    }

    .sidebar-background {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }

    .sidebar-close {
        display: block;
        position: absolute;
        top: 8px;
        right: 15px;
        color: $body-color;
        font-size: 22px;
        cursor: pointer;
        border: 0;
        background: none;
    }

    // .sidebar-offcanvas::-webkit-scrollbar {
    //     display: none;
    // }
    .sidebar-toggle {
        display: block;
        position: fixed;
        top: 50%;
        width: $btn-height;
        height: $btn-height;
        margin-top: -($btn-height * .5);
        background-color: $white-color;
        color: $body-color;
        font-size: 20px;
        line-height: 48px;
        text-align: center;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5); // 0 3px 10px 0 rgba(0, 0, 0, 0.18);
        cursor: pointer;
        z-index: 1000;
        outline-offset: -4px;
        visibility: visible;
        border: 0;

        &.sidebar-open {
            visibility: hidden;
            transition: visibility 0.4s 0.4s;
        }

        &.position-right {
            right: 0;

            // transition: right 0.3s ease-in-out;
            border: {
                top: 1px solid $border-color;
                bottom: 1px solid $border-color;
                left: 1px solid $border-color;
                top-left-radius: $border-radius-base;
                bottom-left-radius: $border-radius-base;
            }

            /*
            &.sidebar-open {
                right: -($btn-height);
            }
            */
        }

        &.position-left {
            left: 0;

            // transition: left 0.3s ease-in-out;
            border: {
                top: 1px solid $border-color;
                right: 1px solid $border-color;
                bottom: 1px solid $border-color;
                top-right-radius: $border-radius-base;
                bottom-right-radius: $border-radius-base;
            }

            /*
            &.sidebar-open {
                left: -($btn-height);
            }
            */
        }
    }
}

// Widget Title
.widget-title {
    position: relative;
    margin-bottom: $grid-vertical-step; //~24px
    padding-bottom: floor($grid-vertical-step * .5); //~12px
    border-bottom: 1px solid $border-color;
    color: $widget-title-color;

    font: {
        size: $widget-title-font-size;
        weight: normal;
    }

    &::after {
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 90px;
        height: 2px;
        background-color: $brand-secondary;
        content: "";
    }
}

// Base Class
.widget {
    margin-bottom: ceil($grid-vertical-step * 1.66); //~40px

    .form-group {
        margin-bottom: 0;
    }

    ul {
        margin-bottom: floor($grid-vertical-step * .5);
        word-break: break-word;
    }

    .market-button:last-child {
        margin-bottom: 0;
    }

    .custom-control:last-child {
        margin-bottom: 0 !important;
    }
}

// Categories Widget
.widget-categories,
.widget-links {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        >li {
            margin-bottom: 5px;

            &:not(.all-filter) {
                position: relative;
                padding-left: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    display: block;
                    position: absolute;
                    top: -1px;
                    left: 0;
                    transform: rotate(-90deg);
                    transition: transform 0.35s;
                    color: $gray;

                    font: {
                        size: 1.15em;
                    }

                    @include icon("chevron-down");
                }
            }

            >a {
                display: inline-block;
                transition: color 0.3s;
                color: $nav-link-color;
                font-size: $widget-link-font-size;
                text-decoration: none;

                &:hover {
                    color: $nav-link-hover-color;
                }
            }

            &.active>a {
                color: $nav-link-active-color;
                font-weight: 500;
            }

            >span {
                margin-left: 4px;
                color: $gray;
            }

            &.has-children {
                ul {
                    border-left: 1px solid shade-color($border-color, 1%);

                    li {
                        &::before {
                            top: 14px;
                            width: 8px;
                            height: 1px;
                            transform: none;
                            border: 0;
                            background-color: shade-color($border-color, 1%);
                            color: transparent;
                        }

                        a {
                            font-size: $font-size-sm;
                        }
                    }

                    ul>li {
                        margin-bottom: 0;
                    }
                }

                >ul {
                    max-height: 0;
                    transition: max-height 0.4s;
                    transition-timing-function: cubic-bezier(0, 1, 0, 1);
                    overflow: hidden;
                }

                &.expanded {
                    &::before {
                        transform: rotate(0);
                    }

                    >ul {
                        max-height: 800px;
                        transition-timing-function: ease-in;
                    }
                }
            }
        }
    }
}

// Featured Products and Cart styles moved to theme/components/widgets/FeaturedProductsEntry.scss and modified by RSA
// Featured Posts
.widget-featured-posts {
    >.entry {
        display: table;
        width: 100%;
        margin-bottom: floor($grid-vertical-step * .75); //~18px

        .entry-thumb,
        .entry-content {
            display: table-cell;
            vertical-align: top;
        }

        .entry-thumb {
            width: $widget-entry-thumb-size + 12;
            // TODO MTM 34: Commented out because the prototype didn't have height
            // We need to determine how we want to scale different ratios of thumbnails
            // An 8.5x11 vs an 11x8.5 thumbnail - do we respect the height or the width?
            // Or just force-fit them to a predetermined size 
            // height: $widget-entry-thumb-size;
            text-align: center;
            padding-right: 12px;

            img {
                max-height: 100%;
                border-radius: $border-radius-base;
                overflow: hidden;
            }

            >a {
                display: block;
            }
        }

        .entry-title {
            margin-bottom: 0;
            font-size: $widget-link-font-size;

            >a {
                transition: color 0.3s;
                color: $nav-link-color;
                font-weight: 400;
                text-decoration: none;

                &:hover {
                    color: $nav-link-hover-color;
                }
            }
        }

        .entry-meta {
            display: block;
            margin-bottom: 0;
            padding-top: 4px;
            color: $widget-meta-color;
            font-size: $widget-meta-font-size;
        }
    }
}

// Featured Products and Cart styles moved to theme/components/widgets/FeaturedProductsEntry.scss and modified by RSA

// Tags Widget
.tag {
    display: inline-block;
    height: $widget-tags-height;

    margin: {
        right: 4px;
        bottom: 8px;
    }

    padding: 0 12px;
    transition-property: background-color,
    border-color,
    color;
    transition-duration: 0.3s;
    border: 1px solid $border-color;
    border-radius: $border-radius-sm;
    color: $nav-link-color !important;
    font-size: $widget-tags-font-size;
    line-height: $widget-tags-height - 2;
    text-decoration: none !important;
    white-space: nowrap;

    &:hover {
        background-color: $gray-lighter;
        color: $nav-link-color !important;
    }

    &.active {
        border-color: $nav-link-active-color;
        background-color: $nav-link-active-color;
        color: $white-color !important;
        cursor: default;
    }
}

.text-end .tag {
    margin-right: 0;
    margin-left: 4px;
}

.text-center .tag {
    margin-right: 2px;
    margin-left: 2px;
}

// Price Range Slider
.noUi-target,
.noUi-target * {
    touch-action: none;
    @include user-select(none);
}

.noUi-target {
    position: relative;
    border-radius: $border-radius-xs;
    direction: ltr;
}

.noUi-base {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* Fix 401 */
}

.noUi-origin {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-stacking .noUi-handle {
    z-index: 10;
}

.noUi-state-tap .noUi-origin {
    transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

.noUi-base,
.noUi-handle {
    transform: translate3d(0, 0, 0);
}

.noUi-horizontal {
    height: 2px;
}

.noUi-horizontal .noUi-handle {
    top: -7px;
    left: -3px;
    width: 8px;
    height: 16px;
}

.noUi-background {
    background: shade-color($gray-lighter, 3%);
}

.noUi-connect {
    transition: background 0.45s;
    background: $gray-dark;
}

/* Handles and cursors;
 */
.noUi-draggable {
    cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: n-resize;
}

.noUi-handle {
    border-radius: $border-radius-xs;
    background: $gray-dark;
    cursor: default;
}

.price-range-slider {
    padding-top: 9px;

    .ui-range-slider-footer {
        display: table;
        width: 100%;
        padding-top: 30px;

        >.column {
            display: table-cell;
            vertical-align: middle;

            &:first-child {
                width: 40%;
            }

            &:last-child {
                width: 60%;
                padding-left: 15px;
                text-align: right;
            }
        }

        .btn {
            height: 28px;
            margin: 0;
            padding: 0 15px;
            line-height: 26px;
        }
    }

    .ui-range-values {
        display: inline-block;

        .ui-range-value-min,
        .ui-range-value-max {
            display: inline-block;
            font-size: $font-size-sm;
        }
    }
}

// Light Skin
.widget-light-skin {
    .widget-title {
        border-color: $border-light-color;
        color: rgba-var($white-color, 0.9);
    }

    &.widget-categories,
    &.widget-links {
        ul>li {
            &::before {
                color: rgba-var($white-color, 0.4);
            }

            >a {
                transition: opacity 0.25s;
                color: $white-color;

                &:hover {
                    opacity: 0.6;
                }
            }

            &.active>a {
                opacity: 0.6;
            }

            >span {
                color: rgba-var($white-color, 0.5);
            }
        }
    }

    a {
        transition: opacity 0.25s;
        color: $white-color;

        &:hover {
            opacity: 0.6;
        }
    }

    color: $white-color;

    &.widget-featured-posts,
    &.widget-featured-products,
    &.widget-cart>.entries {
        >.entry {
            .entry-title>a {
                transition: opacity 0.25s;
                color: $white-color;

                &:hover {
                    opacity: 0.6;
                }
            }

            .entry-meta {
                color: rgba-var($white-color, 0.5);
            }
        }
    }

    .tag {
        border-color: $border-light-color;
        color: $white-color !important;

        &:hover {
            background-color: $white-color;
            color: $nav-link-color !important;
        }

        &.active {
            border-color: $nav-link-active-color;
            background-color: $nav-link-active-color;
            color: $white-color !important;
        }
    }
}