.sure-preview--display {
    background-color: #666;
    color: #fff;
    position: relative;
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.sure-preview--tab-mode-alert {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .alert-box {
        text-align: center;
        background: $white-color;
        color: $gray-dark;
        border-color: $border-color;
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        max-width: 100%;
        width: auto;
        box-shadow: 0 0 4px 1px rgba-var($black-color, 0.5);
        padding: 4px 8px;
    }

    .btn {
        padding-left: 16px;
    }
}
