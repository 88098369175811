.forcedPasswordChange,
.resetPassword,
.forgotPassword {
    background-image: smartImage(carouselBG);
    background-position: center;
    background-color: $white-color;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 7px 30px -6px rgb(0 0 0 / 15%);
    overflow: hidden;

    .container {
        padding-bottom: 72px;
        padding-top: 72px;
        margin-bottom: .5rem;

        .message {
            margin: {
                bottom: 15px;
            }
        }

        .buttons {
            float: right;
        }

        h1 {
            @extend .h4;
        }
    }
}