//
// Spinners
// ----------------------------------------------------

.spinner-border {
    border-width: 0.15em;
}
.spinner-border-sm {
    border-width: 0.1em;
}
