.sure-preview--tab-presets-modal {
    *.row {
        margin-left: 0;
        margin-right: 0;
    }

    .sure-preview--modal--section:not(:first-child){
        @include margin-top(1);
    }
}
