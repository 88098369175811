@use "sass:map";

@import "variables/_baseline.scss";

@import "functions";

@import "variables";
@import "unishop/helpers/variables";

@import url("iconFonts.css");

body {
    overflow-y: scroll;
}
body.scroll-disabled {
    overflow: hidden;
    padding-right: var(--scrollbar-width);
    --hidden-scrollbar-width: var(--scrollbar-width);
}


// This CSS will expand the height of the page so the footer is always flush with the bottom of the screen even
// when the page is too short. Before there would be white space below the footer on very short pages.
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
    
main{
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";

@import "mixins";
@import "unishop/helpers/mixins";

@import "~bootstrap/scss/utilities";
@import "bootstrap-components.scss";
@import "~bootstrap/scss/utilities/api";

@import "unishop/styles.scss";
@import "components";
@import "major-components";
@import "roles";

:root {
    --c-fg-contrast: #{color-contrast($body-color)};
    --c-muted: #{$gray};
    --scrollbar-width: 0;
    --hidden-scrollbar-width: 0;
}