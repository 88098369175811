$border-color: $gray-light;
$border-light-color: rgba-var($white-color, 0.13);

$border-radius-lg: 6px;
$border-radius-base: 5px;
$border-radius-sm: 4px;
$border-radius-xs: 3px;

$modal-footer-border-width: 0;

$item-thumbnail-shadow: 0 0 5px 1px rgba-var($black-color, 0.25);
