.sure-preview--toolbox-container {
    overflow-y: auto;
    background-color: $gray-lighter;
    color: $gray-on-gray-lighter;
    height: 100%;
    @media (max-width: $screen-lg) {
        height: 0px;
    }
}

.sure-preview--toolbox-container.full-screen {
    height: 0px;
}

.sure-preview--toolbox {
    background-color: inherit;
}

.sure-preview--toolbox-background {
    display: none;
    @media (max-width: $screen-lg) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }
}