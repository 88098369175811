.rsa-toast-section {
    display: flex;
    position: fixed;
    &.bottom {
        bottom: 0;
        flex-direction: column;
    }
    &.top {
        top: 0;
        flex-direction: column-reverse;
    }
    right: var(--hidden-scrollbar-width);
    padding: 1em;
    z-index: 9000;
    max-width: 500px;
}