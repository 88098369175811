.upload-drag-container {
    position: relative;
    &.upload-drag-over {
        &::before,
        &::after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        &::before {
            content: "";
            background: $white-color;
            border-radius: inherit;
            border: 2px dashed $gray;
            z-index: 100;
        }
        &::after {
            content: "";
            margin: auto;
            max-height: min(calc(100% - 0.5rem), 100px);
            max-width: min(calc(100% - 0.5rem), 100px);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 101;
        }
    }

    &.upload-drag-over {
        &::after {
            background-image: smartImage(dragAndDropUploadDragOver);
        }
    }
}
