.my-account--distribution-lists--edit-list-cell {
    display: flex;
    justify-content: flex-end;
}

.my-account--distribution-lists--create-list-btn {
    text-align: center;
}

.my-account--distribution-lists--list-name {
    word-break: break-word;
}