//
// Placeholders
// --------------------------------------------------

%sub-menu-action-item {
    // Intentionally empty
}

a,
[role="button"],
[role="menuitem"] {
    @extend %sub-menu-action-item;
}

%sub-menu-like {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 10px 0;
    margin: 0;
    border: 1px solid $border-color;
    background-color: $white-color;
    line-height: $line-height-base;
    box-shadow: $sub-menu-shadow;

    >li {
        display: block;
        position: relative;

        >%sub-menu-action-item {
            display: block;
            position: relative;
            padding: 6px 20px !important;
            transition: color 0.3s;
            color: $gray-dark;
            cursor: pointer;

            &[aria-disabled="true"] {
                cursor: not-allowed;
                opacity: $disabled-button-opacity;
            }

            font: {
                size: $sub-menu-link-font-size;
                weight: normal;
            }

            text: {
                align: left;
                decoration: none;
            }

            >i {
                display: inline-block;

                margin: {
                    top: -2px;
                    right: 6px;
                }

                font-size: 0.9em;
                vertical-align: middle;
            }

            &.p-0 {
                padding: 0 !important;
            }
        }

        &.has-children {
            >%sub-menu-action-item {
                padding-right: 35px !important;

                &::after {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 14px;
                    margin-top: -11px;
                    @include icon("chevron-right");
                }
            }

            >.sub-menu {
                top: -11px;
                left: 100%;
                margin-left: -4px;
            }

            &:not(:first-child)>.sub-menu {
                border-radius: $border-radius-base;
            }
        }
    }
}

// Sub Menu
%sub-menu {
    @extend %sub-menu-like;
    display: none;
    width: $sub-menu-width;

    border: {
        bottom-right-radius: $border-radius-base;
        bottom-left-radius: $border-radius-base;
    }

    >li {

        &:hover,
        &:focus-within,
        &.active {
            >%sub-menu-action-item {
                color: $brand-primary;
            }
        }
    }
}

// Mega Menu
%mega-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    border: {
        top: 1px solid $border-color;
        bottom: 1px solid $border-color;
    }

    background-color: $white-color;
    box-shadow: $sub-menu-shadow;
    table-layout: fixed;

    >li {
        display: table-cell !important;
        position: relative;
        padding: 25px !important;
        border-left: 1px solid $border-color;
        vertical-align: top;

        .mega-menu-title {
            display: block;
            margin-bottom: 16px;
            padding-bottom: 10px; //~12px
            border-bottom: 1px solid $border-color;
            color: $gray;
            text-align: left;
        }

        &:first-child {
            border-left: 0;
        }
    }

    .sub-menu {
        display: block !important;
        position: relative;
        width: 100%;
        padding: 0 !important;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;

        >li>a {
            padding: 5px 0 !important;
        }
    }
}

// Slideable Menu
%slideable-menu {
    position: relative;
    border-top: 1px solid $border-color;
    background-color: $white-color;
    overflow: hidden;

    ul {
        margin: 0;
        padding: 0;
    }

    .menu {
        display: block;
        position: relative;
        transform: translate3d(0, 0, 0);
        transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);

        &.off-view {
            transform: translate3d(-100%, 0, 0);
        }

        &.in-view {
            transform: translate3d(0, 0, 0);
        }

        li {
            display: block;

            a {
                display: block;
                padding: 13px 20px;
                transition: color 0.3s;

                border: {
                    bottom: 1px solid $border-color;
                    left: 2px solid transparent;
                }

                color: $nav-link-color;
                font-size: $font-size-base;
                letter-spacing: 0.025em;

                text: {
                    // transform: uppercase;
                    decoration: none;
                }

                &:hover {
                    color: $nav-link-hover-color;
                }
            }

            &.active>a,
            &.active>span>a {
                border-left-color: $secondary;
                color: $nav-link-active-color;
            }

            &.has-children {
                >span {
                    display: block;
                    position: relative;
                    width: 100%;
                }

                .sub-menu-toggle {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 60px;
                    height: 100%;
                    height: calc(100% - 1px);
                    transition: background-color 0.3s;
                    border-left: 1px solid $border-color;
                    color: $nav-link-color !important;
                    cursor: pointer;
                    z-index: 1;

                    &::before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        margin-top: -11px;

                        font: {
                            size: 22px;
                        }

                        line-height: 1;
                        text-align: center;
                        @include icon("chevron-right");
                    }

                    &:hover {
                        background-color: $gray-lighter;
                    }
                }

                .slideable-submenu {
                    position: absolute;
                    top: 0;
                    right: -100%;
                    width: 100%;
                    height: auto;
                    transform: translate3d(100%, 0, 0);
                    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);

                    &.in-view {
                        transform: translate3d(0, 0, 0);
                    }

                    &.off-view {
                        transform: translate3d(-100%, 0, 0);
                    }
                }
            }

            &.back-btn>a {
                background-color: $gray-lighter;

                &:hover {
                    color: $nav-link-color;
                }

                &::before {
                    display: inline-block;

                    margin: {
                        top: -2px;
                        right: 2px;
                    }

                    font: {
                        size: 22px;
                    }

                    line-height: 1;
                    @include icon("chevron-left");
                    vertical-align: middle;
                }
            }
        }
    }
}

// Sub Menu Animation
@keyframes submenu-show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}