//
// Footer
// --------------------------------------------------

.site-footer {
    background: {
        position: center bottom;
        color: $gray-darker;
        repeat: no-repeat;
        size: cover;
    }

    a {
        text-decoration: none;
    }

    padding-top: $grid-vertical-step;
    padding-bottom: $grid-vertical-step;

    &>.container>.row:not(:empty) {
        margin-top: $grid-vertical-step;
    }

    color: $white-color;
    --c-fg-contrast: #{color-contrast($white-color)};
}

.footer-copyright {
    margin: 0;
    color: rgba-var($white-color, 0.5);

    font: {
        size: $font-size-sm;
        weight: normal;
    }

    >a {
        transition: color 0.25s;
        color: rgba-var($white-color, 0.5);
        text-decoration: none;

        &:hover {
            color: $white-color;
        }
    }

    text-align: center;
}