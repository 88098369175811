.skip-to-main {
    position: absolute;
    top: -4em;
    display: block;
    padding: 0.5em;
    text-align: center;
    outline: 0;
}

.skip-to-main:focus {
    position: static;
}

.skip-to-main-text {
    padding: 0.5em; 
    display: inline-block;
    outline: $focus-width $focus-style $focus-color;
}