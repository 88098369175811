.systemLockout {

    text-align: center;

    h1 {
        margin: {
            top: 15px;
        }
    }

    .button-row {
        margin: {
            top: 25px;
            bottom: 25px;
        }
    }
}