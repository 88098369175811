//
// List Group
// ----------------------------------------------------

$list-group-item-color: $gray-dark;

.list-group-item {
    border-color: $border-color;
    background-color: $white-color;
    color: $list-group-item-color;
    text-decoration: none;
    padding: {
        top: 0.87rem;
        bottom: 0.87rem;
    }
    &:first-child {
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;
    }
    &:last-child {
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
    }
    .icon {
        // display: inline-block;
        // margin-top: -2px;
        // margin-right: 8px;
        font-size: 1.1em;
        // vertical-align: middle;
    }
    // p,
    // ul,
    // ol,
    // li,
    // span {
    //     font-weight: normal !important;
    // }

}
a.list-group-item,
.list-group-item[role="button"],
.list-group-item-action {
    position: relative;
    transition-property: background-color;
    transition-duration: 0.25s;
    &:not(.active):not(.disabled) {
        &:hover,
        &:focus,
        &:active {
            background-color: $gray-lighter;
            color: $list-group-item-color;
        }
    }
}

.list-group-item-after {
    text-decoration: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: $gray;
    font-weight: 500;
}

// With badges
.with-badge {
    position: relative;
    padding-right: 3.3rem;
    .badge {
        position: absolute;
        top: 50%;
        right: 1.15rem;
        transform: translateY(-50%);
    }
}

// Badges
.badge {
    color: $gray-dark;
    font: {
        size: 90%;
        weight: 500;
    }
    & > .icon {
        vertical-align: bottom;
        font-size: 1.1em;
    }
    &.badge-default {
        // border: 1px solid $border-color;
        background-color: $gray-light;
        color: $gray-dark;
    }
    &.badge-primary {
        background-color: $brand-primary;
        color: color-contrast($brand-primary);
    }
    &.badge-info {
        background-color: $brand-info;
        color: color-contrast($brand-info);
    }
    &.badge-success {
        background-color: $brand-success;
        color: color-contrast($brand-success);
    }
    &.badge-warning {
        background-color: $brand-warning;
        color: color-contrast($brand-warning);
    }
    &.badge-danger {
        background-color: $brand-danger;
        color: color-contrast($brand-danger);
    }
}

// Active state
.list-group-item.active {
    border-color: $border-color;
    background-color: $brand-primary;
    color: color-contrast($brand-primary);
    //cursor: default;
    //pointer-events: none;
    &::before {
        opacity: 1;
    }
    /*
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        color: inherit;
    }
    */
}

// Contextual classes
/*
.list-group-item-info {
    @include list-group-variant(shade-color($brand-info, 40%), rgba-var($brand-info, 0.12));
    &::before {
        display: none;
    }
}
.list-group-item-success {
    @include list-group-variant(shade-color($brand-success, 40%), rgba-var($brand-success, 0.12));
    &::before {
        display: none;
    }
}
.list-group-item-warning {
    @include list-group-variant(shade-color($brand-warning, 40%), rgba-var($brand-warning, 0.12));
    &::before {
        display: none;
    }
}
.list-group-item-danger {
    @include list-group-variant(shade-color($brand-danger, 40%), rgba-var($brand-danger, 0.12));
    &::before {
        display: none;
    }
}

.list-group-item-action:focus {
    z-index: 3;
}

.list-group-item-action:hover,
.list-group-item-action.active {
    &.list-group-item-info {
        background-color: rgba-var($brand-info, 0.24);
    }
    &.list-group-item-success {
        background-color: rgba-var($brand-success, 0.24);
    }
    &.list-group-item-warning {
        background-color: rgba-var($brand-warning, 0.24);
    }
    &.list-group-item-danger {
        background-color: rgba-var($brand-danger, 0.24);
    }
}
*/

// Next to card
.card:not([class*="mb-"]):not([class*="margin-bottom-"]) + .list-group {
    margin-top: -1px;
    .list-group-item:first-child {
        border-radius: 0;
    }
}
