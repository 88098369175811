//
// Dropdown
// --------------------------------------------------

// The Unishop ".dropdown-menu" css was intentionally removed (it has accessibility issues) - we use standard Boostrap styling, with some minor tweaks, instead

// Mega Dropdown
.mega-dropdown {
    width: 700px;
    padding: 30px 30px 0;
}

// Toolbar Dropdown
.toolbar-dropdown {
    @extend %sub-menu;
    // This is needed to move the dropdowns into frame when on very small devices
    // Without this they will be cropped off screen.
    right: -1px;
    left: auto;
    z-index: 10;

    &.lang-dropdown {
        width: 150px;

        >li>a {
            font-size: $font-size-sm;
        }
    }

    &.cart-dropdown {
        right: 0;
        width: 320px;
        padding: floor($grid-vertical-step * 0.5);
    }
}

// Show Animation
.show .dropdown-menu {
    animation: dropdown-show 0.25s;
}

@keyframes dropdown-show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}