.login-panel {
    padding: 0;

    .container {
        padding: {
            top: 72px;
            bottom: 72px;
        }
    }

    .side-text {
        margin: {
            bottom: 16px;
        }
    }

    h1 {
        @extend .h4;

        padding: {
            bottom: 24px;
        }

        margin: 0px;
    }

    .login-card {
        h1 {
            @extend .h4;
        }

        hr {
            margin: {
                top: 1rem;
                bottom: 1rem;
            }
        }

        .register-link {
            text-align: center;
        }

        .login-form-button {
            text-align: right;

            button {
                margin: {
                    left: 6px;
                    right: 6px;
                }
            }
        }
    }

    .forgotten-password-link {
        padding: {
            bottom: 24px;
        }
    }

    .product-buttons {
        text-align: right;
    }
}