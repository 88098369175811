@import "react-datepicker";

.date-picker-input {
    color: $gray-dark;
    background-color: $white-color;

    border: 1px solid $border-color;
    border-radius: $border-radius-base;

    height: $input-height;
    padding: 0 0.5em 0 1em;
    font-size: $font-size-md;

    transition: background-color 0.25s;
    &:hover {
        background-color: $gray-lighter;
    }

    &::after {
        @include icon('calendar');
        margin-left: 0.5em;
        color: $primary;
    }
}