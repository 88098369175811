//
// Tooltips and Popovers
// --------------------------------------------------

// Tooltip -- removed by RSA (duplicate of bootstrap scss)

// Popover
.popover {
    border-radius: $border-radius-base;
    border-color: $border-color;
    font-family: $font-family-base;
    &.bs-popover-top .arrow::before {
        border-top-color: shade-color($border-color, 4%);
    }
    &.bs-popover-right .arrow::before {
        border-right-color: shade-color($border-color, 4%);
    }
    &.bs-popover-bottom .arrow {
        &::before {
            border-bottom-color: shade-color($border-color, 4%);
        }
        &::after {
            border-bottom-color: #f7f7f7;
        }
    }
    &.bs-popover-left .arrow::before {
        border-left-color: shade-color($border-color, 4%);
    }
}
.popover-header {
    color: $headings-color;
    font: {
        family: $font-family-headings;
        weight: normal;
    }
}
.popover-body {
    color: $body-color;
}
