.widget-featured-products,
.widget-cart > .entries {
    > .entry {
        text-align: start;
        display: grid;
        grid-template-columns: $widget-entry-thumb-size auto max-content;
        margin-bottom: floor($grid-vertical-step * .5);
        
        .entry-thumb {
            text-align: center;
            height: 100%;
            max-height: $widget-entry-thumb-size;
            display: grid;
            align-items: center;
            img, svg {
                max-width: $widget-entry-thumb-size;
                max-height: $widget-entry-thumb-size;
                border-radius: $border-radius-base;
                overflow: hidden;
            }
            &.thumb-shadow {
                img, svg {
                    box-shadow: $item-thumbnail-shadow;
                }
            }
        
        }
        .entry-content {
            word-break: break-word;
            .entry-meta {
                display: block;
                margin-bottom: 0;
                padding-top: 4px;
                color: $widget-meta-color;
                font-size: $font-size-sm;
            }

            .entry-title {
                margin-bottom: 0;
                font-size: $widget-link-font-size;
                > [role="button"] {
                    transition: color 0.3s;
                    color: $nav-link-color;
                    font-weight: 400;
                    text-decoration: none;
                    &:hover {
                        color: $nav-link-hover-color;
                    }
                }
            }
            padding: 0 12px;
        }
        .entry-modify {
            white-space: nowrap;
            :not(:first-child) {
                margin-left: 4px;
            }
        }
    }
}