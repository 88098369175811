$address-select-modal_search-result_height: calc((8
            /* address block lines */
            * 1.8em
            /* checkout--address-block line height */
        ) + ($grid-vertical-step
            /* result padding */
            + $grid-vertical-step
            /* button padding */
            + $btn-sm-height
            /* button height */
        ));

.address-select-modal--edit-delete-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    gap: 0.5rem;

    .btn {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.address-select-modal--search-result {
    @include padding(0.5);

    @media (min-width: $screen-xl) {
        min-height: $address-select-modal_search-result_height;
    }
}