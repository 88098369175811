.react-datepicker-popper {
    z-index: 1;
    
    &[data-placement^="right"] {
        padding-left: 8px;
    }
    &[data-placement^="left"] {
        padding-right: 8px;
    }

    &[data-placement^="bottom"] {
        padding-top: 4px;
    }

    &[data-placement^="top"] {
        padding-bottom: 4px;
    }
}
