.home-vertical-card-entry.product-card {



    & .product-thumb {

        & img,
        & svg {
            border-radius: $border-radius-base;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    & .product-category {
        color: $gray;
    }
}

.home-vertical-card-container {
    padding-bottom: 30px;
    height: 100%;
}