.surepdf-buttons {
    text-align: center;

    margin: {
        top: 40px;
    }

}

.surepdf-options {
    text-align: center;

    padding: {
        bottom: 72px;
        top: 72px;
    }

    margin: {
        bottom: 0.5rem;
    }
}

.surepdf {
    background-image: 'url("../skin/images/exampleCarouselBG")';
    background-size: cover;
    background-position: center;
}