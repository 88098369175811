.ldapAccountCreation {
    .notFoundMessage {
        margin: 30px 0;
        text-align: center;
    }

    .buttons {
        margin: 30px 0px;
    }

    .modalInstructions {
        margin: {
            bottom: 15px;
        }
    }
}