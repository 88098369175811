.sure-preview--item-summary--category {
    margin-bottom: 19px;
    
    .sure-preview--item-summary--list-title {
        margin-bottom: 0;
    }
    .sure-preview--item-summary--table {
        margin-top: 19px;
        thead {
            background-color: $gray-lighter;
            color: $gray-dark;
        }
    }

    .sure-preview--item-summary--font-substitution-button {
        padding-left: .25em;
    }
}

.sure-preview--item-summary--category:not(:first-child) {
    padding-top: 1z0px;
}

.sure-preview--item-summary--save-options {
    padding-bottom: 14px;
    margin-bottom: 10px;
    border-bottom: 1px solid $gray-light;
}

.sure-preview--font-substitutions--description {
    padding-bottom: 1em;
}