@mixin standard-spacing-lg($property, $size) {
    #{$property}: floor($grid-vertical-step * $size);
}

@mixin standard-spacing-sm($property, $size) {
    #{$property}: floor(($grid-vertical-step * $size) * .667);
}

@mixin standard-spacing($property, $size, $max-width: false) {
    @if $max-width {
        @if $max-width > $screen-md {
            @media (max-width: $max-width) {
                @include standard-spacing-lg($property, $size);
            }
            @media (max-width: $screen-md) {
                @include standard-spacing-sm($property, $size);
            }
        } @else {
            @media (max-width: $max-width) {
                @include standard-spacing-sm($property, $size);
            }
        }
    } @else {
        @include standard-spacing-lg($property, $size);
        @media (max-width: $screen-md) {
            @include standard-spacing-sm($property, $size);
        }
    }
}

@mixin padding($size, $max-width: false) {
    @include standard-spacing(padding, $size, $max-width);
}

@mixin padding-top($size, $max-width: false) {
    @include standard-spacing(padding-top, $size, $max-width);
}

@mixin padding-bottom($size, $max-width: false) {
    @include standard-spacing(padding-bottom, $size, $max-width);
}

@mixin margin-top($size, $max-width: false) {
    @include standard-spacing(margin-top, $size, $max-width);
}

@mixin margin-bottom($size, $max-width: false) {
    @include standard-spacing(margin-bottom, $size, $max-width);
}