
.rsa-toast-container {
    text-align: end;
    margin: 5px 0;
}

.rsa-toast {
    border: 1px solid $border-color;
    border-radius: $border-radius-sm;
    background: $gray-lighter;
    display: inline-flex;
    padding: 8px;
    cursor: default;
    user-select: none;
    align-items: start;
    text-align: left;
    word-break: break-word;

    > .rsa-toast-body {
        display: flex;
        &::before {
            @include icon-font();
            font-size: 20px;
            line-height: 20px;
            margin-right: 8px;
            vertical-align: middle;
        }
    }

    &.rsa-toast-info {
        border-color: rgba-var($brand-info, 0.3);
        background-color: tint-color($brand-info, 90%);
        color: shade-color($brand-info, 40%);
        > .rsa-toast-body::before {
            @include icon-content("help-circle")
        }
    }

    &.rsa-toast-success {
        border-color: rgba-var($brand-success, 0.3);
        background-color: tint-color($brand-success, 90%);
        color: shade-color($brand-success, 40%);
        > .rsa-toast-body::before {
            @include icon-content("check-circle")
        }
    }

    &.rsa-toast-warning {
        border-color: rgba-var($brand-warning, 0.3);
        background-color: tint-color($brand-warning, 90%);
        color: shade-color($brand-warning, 40%);
        > .rsa-toast-body::before {
            @include icon-content("alert-triangle")
        }
    }

    &.rsa-toast-danger {
        border-color: rgba-var($brand-danger, 0.3);
        background-color: tint-color($brand-danger, 90%);
        color: shade-color($brand-danger, 40%);
        > .rsa-toast-body::before {
            @include icon-content("slash")
        }
    }

    > .rsa-toast-close {
        vertical-align: middle;
        transition: opacity 0.25s;
        color: inherit;
        background: none;
        border: 0;
        opacity: .6;

        &::before {
            @include icon("x");
        }
        &:hover {
            opacity: 1;
        }
    }
}

.rsa-toast {
    animation: 0.5s ease-in-out both rsa-toast-fade-in;
}

@keyframes rsa-toast-fade-in {
    from {
        opacity: 0;
        transform: translate3d(200px, 0, 0)
    }
    to {
        opacity: 1;
        transform: none
    }
}

.rsa-toast.rsa-toast-fading {
    // Keep the animation duration in-sync with the javascript, so the element is removed when the animation finishes
    animation: 0.5s ease-in-out both rsa-toast-fade-out;
}

@keyframes rsa-toast-fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}