$form-floating-label-opacity: 1;
$form-floating-label-color: $gray;
$form-floating-placeholder-color: $gray;
$input-padding-y: 0;
$input-padding-x: 18px;
$input-color: $gray-dark;
$input-bg: $white-color;
$input-font-family: $font-family-base;
$input-font-size: $font-size-md;
$input-border-radius: $border-radius-base;
$input-border-width: 1px;
$input-border-color: shade-color($border-color, 2%);
$input-transition: color 0.25s, background-color 0.25s, border-color 0.25s;

$form-check-input-width: 1.143em;

$form-check-input-bg: $gray-lighter;
$form-check-input-border: 1px solid $gray;