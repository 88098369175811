// We don't use the built-in react-datepicker css because...
// 1. We don't need it all, so we want to reduce bloat
// 2. We want to use our theme colors
// 3. It's built for content-box, but bootstrap forces everything to border-box
// @import "~react-datepicker/dist/react-datepicker";

.react-datepicker__header {
    text-align: center;
    background-color: $datepicker-header-bg-color;
    border-bottom: 1px solid $datepicker-border-color;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__current-month {
    font-weight: 500;
    font-size: $font-size-md;
}

.react-datepicker__navigation {
    position: absolute;
    top: 4px;
    padding: 4px 8px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    &:hover {
        background: $gray-light;
    }

    &.react-datepicker__navigation--previous {
        left: 4px;
        &::before {
            @include icon('chevron-left');
        }
    }
    
    &.react-datepicker__navigation--next {
        right: 4px;
        &::before {
            @include icon('chevron-right');
        }
    }
    &::before {
        font-weight: bold;
    }
}


