.checkout--shipping_method, .my_account--shipping_method {
    @include padding-top(2);

    .row {
        margin: 0;
        align-items: center;

        div.form-floating {
            margin-bottom: 0;
        }
    }
}
