@import "ship-date";

.checkout--extended-delivery-notice {
    padding-top: 24px;
    padding-bottom: 48px;
    color: $warning;
}

.checkout--extended-delivery-label {
    color: $warning !important;
    display: inline;
    cursor: pointer;
}

.checkout--rush-label {
    text-align: center;
}

.checkout--shipping-policy {
    padding-top: 24px;
    padding-bottom: 24px;
}

.checkout--multi-recipient-table {
    .table-list-cell {
        vertical-align: top !important;
    }
    button {
        word-wrap: normal;
    }
}

.checkout--multi-recipient--recipient-count {
    text-wrap: nowrap;
}

.checkout--shipping-method-name-icon > i {
    padding-right: 4px;
}

.checkout--multi-recipient--action-block {  
    padding-top: 1em;  
    button {
        display: block;
        margin-top: 10px !important; 
    }
    .form-floating {
        margin-bottom: 0px;
    }
}

.checkout--multi-recipient--shipper-account {
    margin-left: 4px;
}

.small-items-table {
    .table-list-header {
        background-color: $focus-color-light;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        word-break: normal;
    }
    i {
        margin-left: 4px;
    }
}

.small-items-table-compact {
    margin-top: 1em;
    i {
        margin-left: 4px;
    }
}

.checkout--multi-recipient--recipient-quantities-modal {
    .btn.btn-inline {
        white-space: break-spaces;
    }
}

.checkout--shipping-method-pickup-message,
.checkout--shipping-method-account-number {
    @extend .text-muted;
    @extend .text-sm;
}