.sure-preview--other-finishing-modal{
    .form-floating {
        margin-bottom: 0;
    }

    .row {
        padding-bottom: floor($grid-vertical-step * 0.833);
    }
    
    .form-check-label {
        word-wrap: break-word;
        max-width: 100%;
    }
}