// We don't use the built-in react-datepicker css because...
// 1. We don't need it all, so we want to reduce bloat
// 2. We want to use our theme colors
// 3. It's built for content-box, but bootstrap forces everything to border-box
// @import "~react-datepicker/dist/react-datepicker";

$datepicker-border-color: $gray-light;
$datepicker-header-bg-color: $gray-lighter;
$datepicker-bg-color: $body-bg;
$datepicker-fg-color: $body-color;

.react-datepicker {
    font-family: $font-family-base;
    font-size: $font-size-sm;
    background-color: $datepicker-bg-color;
    color: $datepicker-fg-color;
    border: 1px solid $datepicker-border-color;
    border-radius: $border-radius-base;
    display: inline-block;
    position: relative;
}

@import "react-datepicker-popper";
@import "react-datepicker-header";
@import "react-datepicker-days";
