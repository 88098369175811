.inline-confirmation {
    background: $white-color;
    box-shadow: 0 0 4px 1px rgba-var($black-color, 0.5);
    padding: 4px;
    border-radius: $border-radius-base;
    text-align: center;

    .inline-confirmation--prompt {
        white-space: normal;
    }
    .btn {
        margin-top: 4px;
        margin-bottom: 0;
        .formatted-string {
            white-space: nowrap;
        }
    }
}