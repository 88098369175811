.sure-preview--title-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    & > .btn {
        margin: 0;
    }
    & > .icon-btn-inline {
        font-size: 1.2em;
    }
}