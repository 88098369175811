.formatted-string {
    white-space: pre-wrap;
}

.btn .formatted-string {
    white-space: pre;
}

.formatted-string-debug .formatted-string[data-source] {
    position: relative;
    &::after {
        content: attr(data-source);
        background-color: #f0f;
        color: #000;
        border: 1px dotted #000;
        position: absolute;
        left: 0;
        top: 0.2em;
        font-size: 0.8em;
        line-height: initial;
        padding: 0 0.3em;
    }
}
