.checkout--review--section-bottom {
    padding-top: 24px;
    margin-top: 1rem;

    div [class^="col-"],
    div[class*=" col-"] {
        margin-bottom: 20px;
    }
}

.checkout--review--section-bottom-block table {
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px;
    line-height: 1.8;

    td {
        vertical-align: top;
        text-align: left;

        border: {
            bottom: 1px dashed $gray-light;
        }
    }
}

.checkout--review--required-credit-card {
    margin-left: 10px;
}

.checkout--review--section-bottom-block-title {
    @extend .h5;

    button {
        transition: color .3s;
        color: $gray-darker;
        line-height: 1.5;

        i {
            padding-left: 8px;
            color: $primary;
        }
    }

    button:hover {
        color: $primary;
        text-decoration: underline;
    }
}

.checkout--review--use-credit-card-row {
    margin-bottom: 15px;
}

.checkout--review--use-credit-card {
    color: $gray;
}

.checkout--review--account-code-name {
    color: $gray
}


// TODO MTM 34: Temporary CSS for styling locked accounts codes, we need a design for this still
// No point in making a Billing.scss yet for just this temp
.checkout--billing--account-code-locked {
    font-weight: bold;
    color: $gray;
    display: block;
    padding-top: 10px;
    padding-left: 10px;
}

.autostock-quantity-modal {
    text-align: center;

    .row {
        h1 {
            @extend h6;
        }
    }
}

.autostock-quantity {
    margin-right: 5px;
}

.autostock-section-multiple-recipients {
    text-align: right;
    font-size: 2em;

    div {
        margin: 10px;
    }

    div.not-enough-available {
        color: $brand-danger;
    }
}

.approved-status {
    // Slightly darker green to be AA compliant against a green background
    color: #006600;
}

.rejected-status {
    // Slightly darker red to be AA compliant against a red background
    color: #b21010;
}