.single-container-page {
    &.container {
        padding: {
            bottom: 72px;
        }

        .card {
            text-align: center;

            .card-body {
                padding-top: 48px;

                .card-title {
                    @extend .h3;
                }

                .card-text {
                    div {
                        padding-bottom: 16px;
                    }
                }

            }
        }
    }
    &.single-container-page-untitled {
        padding: {
            top: 40px;
        }
    }
}
