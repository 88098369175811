.page-loader-container {
    padding: 4em 0;
    text-align: center;
    & > .page-loader {
        text-align: center;
        width: 85%;
        max-width: 350px;
        min-width: 150px;
        display: inline-block;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        &.show {
            opacity: 1;
        }
    }
}