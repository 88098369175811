.section {
    .section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .section-buttons {
            text-align: right;
        }
    }

    &>h2 {
        @extend .h4;
        padding-bottom: floor($grid-vertical-step * .5);
        border-bottom: 1px solid $border-color;
        margin-bottom: 2px;
    }

    &>h3 {
        @extend .h5;
    }

    &>h4 {
        @extend .h6;
    }

    &>h5 {
        @extend .h7;
    }

    .section-message {
        padding-bottom: 4px;

        &::before {
            // Zero-width space, to make sure we always take up the right vertical space even if there is no message
            content: "​";
        }
    }
}