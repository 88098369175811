.sure-preview--document-preview--navigation {
    position: absolute;
    bottom: 0;
    z-index: 1;
    background-color: inherit;
    color: inherit;
    width: 100%;
    opacity: 0.8;
    padding: 0.5rem 0;
    align-items: baseline;

    .icon-btn-inline {
        color: $white-color;
        outline-color: $white-color;
        font-size: 24px;
    }

    .sure-preview--navigation--left {
        text-align: left;
    }

    .sure-preview--navigation--right {
        text-align: right;
    }

    .sure-preview--navigation--left,
    .sure-preview--navigation--right {
        .icon-btn-inline:not(:first-child) {
            margin-left: 1rem;
        }
    }
}
