*:focus {
    outline: $focus-width $focus-style $focus-color;
    &.text-light,
    &.text-white {
        outline-color: $focus-color-light;
    }
    z-index: 100;
    &:not(:focus-visible) {
       outline: 0;
    }
}

.btn-link-primary.btn-inline:focus {
    outline-offset: 1px;
}

.btn:not(.btn-link-primary):focus {
    outline-offset: -1 * $focus-width - 3px;
    outline-color: currentColor;
}

.checkout-step:focus {
    outline: 0;
    & > .step-text {
        outline: $focus-width $focus-style $focus-color;
        outline-offset: 10px;
    }
    &:not(:focus-visible) > .step-text {
       outline: 0;
    }
}

a.site-logo,
.site-menu li > a,
.toolbar-dropdown li > a,
.toolbar-item > a,
.toolbar-item > button {
    outline-offset: -1 * $focus-width;
}

// See also inputs/checkboxAndRadio.scss for focus outlines for checkbox and radio inputs
input {
    &:focus {
        outline: 0;
        border-color: $focus-color;
        box-shadow: none !important;
    }
}
