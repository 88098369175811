//
// Pagination + Post Navigation
// --------------------------------------------------

// Pagination
.pagination {
    display: table;
    width: 100%;
    border-top: 1px solid $border-color;
    > .column {
        display: table-cell;
        padding-top: floor($grid-vertical-step * .667); //~16px
        vertical-align: middle;
    }
    .pages {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        > li {
            display: inline-block;
            padding-right: 3px;
            font: {
                size: $pagination-link-font-size;
                weight: $pagination-link-font-weight;
            }
            text-align: center;
            > * {
                display: block;
                width: $pagination-link-size;
                height: $pagination-link-size;
                line-height: $pagination-link-size - 2;
            }
            > a {
                transition-property: background-color, border-color, color;
                transition-duration: 0.3s;
                border: 1px solid transparent;
                border-radius: $border-radius-sm;
                color: $pagination-link-color;
                text-decoration: none;
                &:hover {
                    border-color: $border-color;
                    background-color: $pagination-link-hover-bg;
                }
            }
            &.active > a {
                border-color: $pagination-link-active-bg;
                background-color: $pagination-link-active-bg;
                color: $pagination-link-active-color;
            }
        }
    }
    .btn-sm {
        font-size: $font-size-sm;
        > i {
            font-size: 1.1em;
        }
    }
}

// Entry Navigation
.entry-navigation {
    display: table;
    width: 100%;
    border: {
        top: 1px solid $border-color;
        bottom: 1px solid $border-color;
    }
    table-layout: fixed;
    > .column {
        display: table-cell;
        padding: {
            top: 15px;
            bottom: 15px;
        }
        text-align: center;
        vertical-align: middle;
    }
    .btn-sm {
        font-size: $font-size-sm;
        > i {
            font-size: 1.1em;
        }
    }
    .btn {
        margin: 0;
        &.view-all {
            width: $btn-height;
            padding: {
                right: 0;
                left: 1px;
            }
            > i {
                margin-top: -2px;
                font-size: 1.4em;
            }
        }
    }
}
