.table-multi-striped {
    tbody:nth-of-type(odd) {
        background-color: $gray-lighter;
    }
    &.table-inverse {
        tbody:nth-of-type(odd) {
            background-color: rgba-var($black-color, 0.08);
        }
    }
    th, td {
        border-color: $gray-on-gray-lighter !important;
    }
}