$distribution-list-edit-modal_search-result_height: calc((8
            /* address block lines */
            * 1.8em
            /* checkout--address-block line height */
        ) + ($grid-vertical-step
            /* result padding */
            + $grid-vertical-step
            /* button padding */
            + $btn-sm-height
            /* button height */
        ));

.distribution-list-edit-modal--edit-delete-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 0.5rem;

    .btn, .form-floating {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.distribution-list-edit-modal--search-result {
    @include padding(0.75);

    @media (min-width: $screen-xl) {
        min-height: $distribution-list-edit-modal_search-result_height;
    }
}