.home-iconographic-entry {
    text-align: center;
    padding: 0.5rem 0;
    margin: 0.5rem 0;

    & .home-iconographic-icon {
        display: block;
        & + * {
            margin-top: 1rem;
        }
    }

    & .home-iconographic-entry-title {
        @extend .h6;
        & + * {
            margin-top: 0.5rem;
        }

        // We want the foreground color from parent instead of the Heading color
        color: inherit;
    }

    & .home-iconographic-entry-meta {
        font-size: 13px;

        // We want the foreground color from parent instead of the Heading color
        color: inherit;
        opacity: 0.60;
    }
}