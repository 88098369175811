.sure-preview--grid-view--entry {
    padding: 10px;
    user-select: none;
    &.selected {
        background-color: $primary;
        color: color-contrast($primary);

        .sure-preview--grid-view--entry--details svg,
        .sure-preview--grid-view--entry--details img {
            @include outlined-image();
        }
    }
}

.sure-preview--grid-view--entry--details {
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}
