.header-icon>i {
    font-size: 2em;
    display: inline-block;
    margin-bottom: 6px;
}

.toolbar-text-with-badge,
.header-icon-with-badge {
    position: relative;

    .count-label {
        $badge-height: 18px;
        min-width: $badge-height;
        height: $badge-height;
        border-radius: $badge-height * 0.5;
        font-size: 11px;
        line-height: $badge-height;
    }
}

.header-icon-with-badge {
    display: inline-block;

    .count-label {
        display: block;
        position: absolute;
        top: -6px;
        right: -13px;
        padding: 0 4px;
    }
}

.toolbar-text-with-badge {
    .count-label {
        display: inline-block;
        position: relative;
        bottom: 1em;
    }
}

.with-badge-primary .count-label {
    background-color: $secondary;
    color: color-contrast($secondary);
}

.with-badge-secondary .count-label {
    background-color: $secondary;
    color: color-contrast($secondary);
}

.with-badge-danger .count-label {
    background-color: $brand-danger;
    color: color-contrast($brand-danger);
}

.header-icon-count {
    transition: background-color 1s;

    &.header-icon-count--changed-recently {
        &.with-badge-primary {
            background-color: tint-color($brand-primary, 90%)
        }

        &.with-badge-secondary {
            background-color: tint-color($gray-dark, 90%)
        }

        &.with-badge-danger {
            background-color: tint-color($brand-danger, 90%)
        }
    }
}