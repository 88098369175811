$slick-arrow-size: 46px;
$slick-arrow-offset: 18px;
$slick-arrow-font-size: 22px;

.slick-slider {
    min-height: $slick-arrow-offset * 2 + $slick-arrow-size;
}

.slick-slide {

    // Layers animation
    .from-top,
    .from-bottom,
    .from-left,
    .from-right,
    .scale-up,
    .scale-down {
        transition-property: opacity, transform;
        transition-duration: 0.45s;
        transition-delay: 0.3s;
        transition-timing-function: ease-in-out;
        opacity: 0;
    }

    .from-top {
        transform: translateY(-45px);
    }

    .from-bottom {
        transform: translateY(45px);
    }

    .from-left {
        transform: translateX(-45px);
    }

    .from-right {
        transform: translateX(45px);
    }

    .scale-up {
        transform: scale(0.8);
    }

    .scale-down {
        transform: scale(1.2);
    }

    .delay-1 {
        transition-delay: 0.5s;
    }

    .delay-2 {
        transition-delay: 0.7s;
    }

    .delay-3 {
        transition-delay: 0.9s;
    }

    .delay-4 {
        transition-delay: 1.1s;
    }

    &.slick-active {

        .from-top,
        .from-bottom {
            transform: translateY(0);
            opacity: 1;
        }

        .from-left,
        .from-right {
            transform: translateX(0);
            opacity: 1;
        }

        .scale-up,
        .scale-down {
            transform: scale(1);
            opacity: 1;
        }
    }
}

.slick-arrow {
    position: absolute;
    bottom: $slick-arrow-offset;
    padding: 0;
    width: $slick-arrow-size - 1;
    line-height: $slick-arrow-size - 2px;
    border: 1px solid $gray-light;
    border-left: 0;
    background: #fff;
    font-size: $slick-arrow-font-size;
    color: $gray-dark;

    i {
        opacity: 0.65;
    }

    &:focus i,
    &:hover i {
        opacity: 1;
    }

    &.slick-next {
        right: $slick-arrow-offset;
        border-radius: 0 $border-radius-sm $border-radius-sm 0;
    }

    &.slick-prev {
        right: $slick-arrow-offset + $slick-arrow-size;
    }
}

.slick-dots {
    position: absolute;
    bottom: $slick-arrow-offset;
    right: $slick-arrow-offset + 2 * $slick-arrow-size;
    list-style: none;
    padding: 0 8px;
    margin: 0;
    border-radius: $border-radius-sm 0 0 $border-radius-sm;
    border: 1px solid $gray-light;
    background: #fff;

    li {
        display: inline-block;

        button {
            background: none;
            border: none;
            padding: 0;
            line-height: $slick-arrow-size - 2px;
            margin: 0;
            padding: 0 $slick-arrow-font-size * 0.5;
            font-size: 0;
            position: relative;
            color: $gray;
            opacity: 0.65;

            &:focus,
            &:hover {
                color: $gray-dark;
                opacity: 1;
            }

            &::before {
                content: '·';
                position: absolute;
                font-size: $slick-arrow-font-size * 1.25;
                left: 0;
                right: 0;
            }
        }

        &.slick-active {
            button {
                color: $brand-primary;
            }
        }
    }
}