.sure-preview--toolbox {
    .toolbox-entry {
        display: grid;
        grid-template-columns: min-content auto min-content;
        gap: 0.5rem;
        align-items: center;

        .toolbox-entry--not-shown-hint {
            font-size: 0.9em;
        }

        .toolbox-entry--value {
            font-weight: bold;
            &:empty::before {
                // Zero-width space, to make sure we always take up the right vertical space even if the option is the empty string
                content: "​";
            }
        }
    }


    .toolbox-entry--image svg,
    .toolbox-entry--image img {
        max-height: none; /* Remove max-height restriction. The current max-height 100% breaks Safari Preview left side panel.  */
        height: auto; /* Optional: set height to auto */
    }

    .toolbox-entry--image {
        @include centered-icon-container(30px);
        @include outlined-image();
    }
}
