.privacyPolicyModal {
    .privacyPolicyText {
        border: 1px solid black;
        background-color: $gray-light;
        padding: 10px;

        margin: {
            top: 10px;
        }
    }

    .bottomButton {
        margin: {
            top: 10px;
        }

        text-align: center;
    }
}