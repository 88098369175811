.shop-toolbar {
    padding-bottom: 24px;
    margin-bottom: .5rem;

    .item-count {
        margin-left: 10px;
        margin-right: 5px;
    }
}

@media (min-width:$screen-sm) {

    // On smaller devices each control will have its own line, the default block of the <div> tag
    // On larger devices we have enough room, so turn that div into a span so all the controls can be on one line
    .dropdown-container {
        display: inline-block;
    }

    .product-card.product-list {
        .instructions {
            font-size: 13px;
            color: $gray;
            margin-bottom: .25rem;
        }
    }
}

.price-tiers-table {
    width: 100%;

    td,
    th {
        padding: 10px;
        border: 1px solid $gray;
    }
}

.product-list {
    .product-thumb {
        // Keeps the badges within the thumbnail in the list view
        position: relative;
    }
}

// Various badge colors
.product-badge-container {
    z-index: 400;
    display: block;
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;

    // Ignore all pointer events for this badge container and let clicks pass through to the thumbnail below
    pointer-events: none;

    .product-badge {
        display: inline-block;

        &.in-book-badge {
            background-color: $primary;
            color: color-contrast($primary);
        }

        &.site-locked-badge {
            background-color: $gray;
        }

        &.backorders-only-badge {
            background-color: $gray;
        }

        &.expires-soon-badge {
            background-color: $warning;
        }

        &.out-of-stock-badge {
            background-color: $danger;
        }
    }
}