.modal-title {
    color: $headings-color;

    font: {
        family: $font-family-headings;
        style: $font-style-headings;
        size: $font-size-h4;
        weight: $font-weight-h4;
    }

    text-transform: $text-transform-headings;

    &+.icon-btn-inline {
        /* close button */
        transition: color 0.25s;
        border: 0;
        background: 0;
        color: $gray-dark;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }
}

.modal-footer {
    &.modal-footer--center {
        justify-content: center;
    }

    &.modal-footer--left-center-right {
        justify-content: space-between;
    }
}