.address-select-modal--current-address {
    background-color: $gray-lighter;
    @include margin-top(1.5);
    @include padding(1);
}

.address-select-modal--current-address-title {
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: underline;

    margin: {
        bottom: 20px;
    }
}