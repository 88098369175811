.sure-preview--footer {
    background-color: $gray-darker;
    color: $white-color;
    padding: 8px 15px;

    > .sure-preview--footer--message {
        text-align: center;
        margin-bottom: 1rem;
    }

    .sure-preview--footer--buttons {
        display: grid;
        grid-template-columns: auto auto;

        .sure-preview--status,
        .sure-preview--footer--message {
            display: inline-block;
            vertical-align: middle;
        }

        > div > * {
            vertical-align: baseline;
        }

        .sure-preview--status,
        .sure-preview--footer--message,
        > :first-child .btn {
            margin-right: 1rem;
        }
        
        > :last-child {
            text-align: right;
        }
    }
}
