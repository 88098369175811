.toolbar-item.expanded {
    background-color: $toolbar-item-bg;
}

.toolbar-item.expanded>.toolbar-dropdown {
    display: block;
    animation: submenu-show 0.35s;
}

.toolbar-dropdown .has-children.expanded-left>.sub-menu {
    left: auto;
    right: 100%;
}

.toolbar-dropdown .has-children>.sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    padding: 10px 0;
    border: 1px solid $gray-light;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: $white-color;
    line-height: 1.5;
    box-shadow: 0 7px 22px -5px rgba-var($black-color, 0.2);
    list-style: none;
}

.toolbar-dropdown .sub-menu>li {
    display: block;
    position: relative;
}

.toolbar-dropdown .has-children.expanded>.sub-menu {
    display: block;
    animation: submenu-show 0.35s;
}

.toolbar-dropdown .has-children>.sub-menu {
    top: -11px;
    left: 100%;
    margin-left: -4px;
}

.toolbar-dropdown .has-children>.sub-menu>li>a {
    display: block;
    position: relative;
    padding: 6px 20px !important;
    transition: color 0.3s;
    color: $gray-dark;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    text-decoration: none;
}

.toolbar-dropdown .has-children>.sub-menu>li:hover>a {
    color: var(--c-primary);
}

.toolbar-dropdown .has-children.expanded>.sub-menu {
    display: block;
}