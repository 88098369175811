.surepdf-instructions,
.surepdf-alert {
    margin-bottom: 20px;
    text-align: center;
}

.surepdf-card-col {
    text-align: center;
    @include margin-bottom(1);
}

.surepdf-card {
    max-width: 465px;
    width: 100%;
    height: 100%;
    padding: 18px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.surepdf-description {
    font-size: $font-size-sm;
    color: $gray;
    padding-top: 10px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}