.form-floating {
    margin-bottom: floor($grid-vertical-step * 0.833); //~20px

    &.no-margin {
        margin-bottom: 0;
    }

    &.has-state-icon {
        $state-icon-size: 1.2rem;

        & .form-control {
            padding-right: calc($form-floating-padding-x * 1.5 + $state-icon-size);

            &~.state-icon {
                color: $gray;
                position: absolute;
                top: 0.9rem;
                right: $form-floating-padding-x * 0.75;
                display: inline-block;
                pointer-events: none;
                font-size: $state-icon-size;
                border: $input-border-width solid transparent;
            }
        }

        & .state-icon {
            display: none;
        }
    }

    &::after {
        // Note: This is needed to ensure the textarea-n-of-m div takes up vertical space
        // even if there isn't also help text (since it is a float)
        content: "";
        display: block;
        clear: both;
    }

    & .form-control~label {
        color: $form-floating-placeholder-color;
    }

    &>.form-control:focus~label,
    &>.form-control:not(:placeholder-shown)~label,
    &>.form-select~label {
        color: $form-floating-label-color;
    }

    & textarea.form-control {
        height: auto;
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;

        &:not(:placeholder-shown)~label {

            // Patch for https://github.com/twbs/bootstrap/issues/32800 (Part 3 of 3)
            &::before {
                content: "";
                position: absolute;
                top: 0.9em;
                left: 0.5 * $form-floating-padding-x;
                z-index: -1;
                width: calc(100% - $form-floating-padding-x);
                height: 1.75em;
                background-color: white;
                box-shadow: 0 0 4px #ffffff;
            }
        }
    }

    &>.form-text {
        padding: 0 calc($form-floating-padding-x + 1px);
    }
}

.textarea-n-of-m {
    float: right;

    &.textarea-at-maxlength {
        color: $warning;
    }
}
