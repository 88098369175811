.reviewSystemComment {
    font-weight: bold;
    font-style: italic;
    color: $gray;
}

span.overridden {
    color: #EE0000;
}

SPAN.overridden {
    text-decoration: line-through;
}

.shopping-cart {
    .downloadButton {
        text-align: right;
    }
}

.attached-files {
    margin-bottom: 72px;
}

.hardcopyContainer {
    margin-top: 15px;
}

.attachedFileName {
    margin-left: 10px;
}