.tabbed-set {
    >.nav-tabs {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    >.tab-content {
        margin-top: 0;
    }
}