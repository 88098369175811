.btn.btn-inline {
    height: auto;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    margin: 0;
    text-align: inherit;
    vertical-align: inherit;
    border: 0;
    border-radius: 0;
}
