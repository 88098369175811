.card-img-tiles {
    display: grid;
    grid-template-columns: 2fr 1fr;
    > div {
        padding: 15px;
        aspect-ratio: 1;
        &:first-child {
            grid-row: 1 / 3;
            &:not(:only-child) {
                border-right: 1px solid $gray-lighter;
            }
            &:only-child {
                grid-column: 1 / 3;
                aspect-ratio: 3 / 2;
            }
        }
        &:nth-child(2) {
            border-bottom: 1px solid $gray-lighter;
        }
    }

    .card-img-contain {
        text-align: center; // For when SmartImages are used instead of background images
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
        // filter: drop-shadow(0 0 1px rgba(0,0,0,0.5)); -- option to give "borders" around the bg... may not work in all browsers
    }
}
