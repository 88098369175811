//
// Forms
// --------------------------------------------------

.form-control {

    /* Removed by RSA - handled by bootstrap variables and the core bootstrap forms SCSS
    padding: 0 18px;
    transition: color 0.25s, background-color 0.25s, border-color 0.25s;
    border: 1px solid shade-color($border-color, 2%);
    border-radius: $border-radius-base;
    background-color: $white-color;
    color: $gray-dark;//$body-color;
    font: {
        family: $font-family-base;
        size: $font-size-md;
    }
    @include appearance(none);
    */
    &:not(textarea) {
        height: $input-height;
    }

    // Placeholder
    @include placeholder;

    // Focus state
    // &:focus {
    //     border-color: $input-focus-color;
    //     outline: none;
    //     background-color: rgba-var($input-focus-color, 0.02);
    //     color: $gray-dark;
    //     box-shadow: none !important;
    // }
    &[type="color"] {
        padding-bottom: 0 !important;
    }

    // Disabled
    &:disabled,
    &[readonly] {
        background-color: $gray-lighter;
        cursor: not-allowed;
    }
}

// Textarea
textarea.form-control {
    padding: {
        top: 12px;
        bottom: 12px;
    }
}

// Select
select.form-control {
    padding-right: 38px;

    background: {
        position: center right 17px;
        image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDE4NS4zNDQgMTg1LjM0NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTg1LjM0NCAxODUuMzQ0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTkyLjY3MiwxNDQuMzczYy0yLjc1MiwwLTUuNDkzLTEuMDQ0LTcuNTkzLTMuMTM4TDMuMTQ1LDU5LjMwMWMtNC4xOTQtNC4xOTktNC4xOTQtMTAuOTkyLDAtMTUuMTggICAgYzQuMTk0LTQuMTk5LDEwLjk4Ny00LjE5OSwxNS4xOCwwbDc0LjM0Nyw3NC4zNDFsNzQuMzQ3LTc0LjM0MWM0LjE5NC00LjE5OSwxMC45ODctNC4xOTksMTUuMTgsMCAgICBjNC4xOTQsNC4xOTQsNC4xOTQsMTAuOTgxLDAsMTUuMThsLTgxLjkzOSw4MS45MzRDOTguMTY2LDE0My4zMjksOTUuNDE5LDE0NC4zNzMsOTIuNjcyLDE0NC4zNzN6IiBmaWxsPSIjNTA1MDUwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
        repeat: no-repeat;
        size: 10px 10px;
    }

    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

// Form group
.form-group {
    margin-bottom: floor($grid-vertical-step * .833); //~20px

    label {
        margin-bottom: 8px;
        padding: 0 18px;

        font: {
            size: $font-size-xs;
            weight: 500;
        }

        cursor: pointer;

        &.col-form-label {
            align-self: center !important;
            margin-bottom: 0;
        }
    }
}

// Input sizes
.form-control-lg {
    border-radius: $border-radius-lg;
    font-size: $font-size-base;

    &:not(textarea) {
        height: $input-height-lg;
    }

    &.form-control-pill {
        border-radius: ceil($input-height-lg * .5);
    }

    &.form-control-square {
        border-radius: 0;
    }
}

select.form-control.form-control-lg {
    &:not([size]):not([multiple]) {
        height: $input-height-lg;
    }
}

.form-control-sm {
    // padding-bottom: 2px;
    border-radius: $border-radius-sm;
    font-size: $font-size-sm;

    &:not(textarea) {
        height: $input-height-sm;
    }

    &.form-control-pill {
        border-radius: ceil($input-height-sm * .5);
    }

    &.form-control-square {
        border-radius: 0;
    }
}

select.form-control.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

// Form text
.form-text {
    padding: 0 18px;
}

// Checkboxes & Radio buttons
.custom-control {
    margin-bottom: 6px !important;
    padding-left: 1.5rem !important;

    // &:focus {
    //     outline: 0;
    // }
    .custom-control-label {
        margin: 0;
        padding-left: 0;

        font: {
            size: $font-size-md !important;
            weight: normal !important;
        }

        text-transform: none;

        &::before {
            border: 1px solid shade-color($border-color, 2%);
            background-color: $gray-lighter;
            box-shadow: none !important;
        }
    }

    .custom-control-input:checked~.custom-control-label::before {
        border-color: $brand-primary;
        background-color: $brand-primary;
    }

    .custom-control-input:disabled~.custom-control-label {
        color: $gray;
        cursor: not-allowed;
    }
}

.bg-secondary .custom-control {
    .custom-control-label::before {
        background-color: $white-color;
    }
}

.custom-control-inline {
    align-items: center;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-size: 12px 12px;
}

// File input
.hidden-file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.custom-file {
    height: $input-height;
    border-radius: 5px;
    cursor: pointer;

    .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        margin: 0;
        opacity: 0
    }

    .custom-file-label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: .375rem 18px;
        background-color: $white-color;
        border: 1px solid $gray-light;
        font-size: 14px !important;
        height: $input-height;

        border-radius: 5px;
        border-color: $gray-light;
        color: $gray;
        line-height: 2.35;
        font-weight: 400 !important;

        &.file-selected {
            color: $brand-primary;
            font-style: italic;
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: block;
            padding: .375rem .75rem;
            border-left: inherit;
            height: 44px;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            background-color: $gray-lighter;

            border-radius: 5px;
            border-color: $gray-light;
            color: $gray;
            line-height: 2.35;
            font-weight: 400 !important;
        }
    }
}

.custom-file-input:focus~.custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, 0.25)
}

.custom-file-input:disabled~.custom-file-label {
    background-color: $gray-lighter;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-input:focus~.custom-file-label,
.custom-file:focus~.custom-file-label {
    box-shadow: none
}

// Input shapes
.form-control-pill {
    border-radius: ceil($input-height * .5);

    .custom-file-control,
    .custom-file-control::before {
        border-radius: ceil($input-height * .5);
    }
}

.form-control-square {
    border-radius: 0;

    .custom-file-control,
    .custom-file-control::before {
        border-radius: 0;
    }
}

// Input group
.input-group {
    display: block;
    position: relative;

    .input-group-addon,
    .input-group-btn {
        display: inline-block;
        position: absolute;
        top: 50%;
        margin-top: 2px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 1.1em;
    }

    .input-group-btn {
        margin-top: 3px;
    }

    .input-group-addon {
        left: 15px;
        transition: color 0.3s;
        background-color: transparent !important;
        color: $gray;
    }

    .form-control {
        padding-left: 37px;

        &:focus~.input-group-addon {
            color: $brand-primary;
        }
    }

    .input-group-btn {
        right: 10px;

        button {
            transition: color 0.3s;
            border: 0;
            background: 0;
            color: $gray-dark;
            font-size: 1.2em;
            cursor: pointer;

            &:hover {
                color: $brand-primary;
            }
        }

        .btn {
            margin: 0;

            margin: {
                top: -8px;
                right: 3px;
                bottom: 0;
                left: 0;
            }

            padding: 0;
        }
    }

    .input-group-btn~.form-control {
        padding: {
            right: 38px;
            left: 18px;
        }
    }
}

// Light Skin
.input-light {

    &.form-control,
    .form-control {
        border-color: rgba-var($white-color, 0.18);
        background-color: rgba-var($white-color, 0.02);
        color: $white-color;
        @include placeholder(rgba-var($white-color, 0.5));

        &:focus {
            border-color: rgba-var($white-color, 0.3);
            background-color: rgba-var($white-color, 0.04);
            color: $white-color;

            ~.input-group-addon {
                color: $white-color;
            }
        }
    }

    &.input-group {
        .input-group-addon {
            color: rgba-var($white-color, 0.5);
        }
    }
}

.input-light select.form-control,
select.form-control.input-light {
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI4NC45MjkgMjg0LjkyOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg0LjkyOSAyODQuOTI5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Mi4wODIsNzYuNTExbC0xNC4yNzQtMTQuMjczYy0xLjkwMi0xLjkwNi00LjA5My0yLjg1Ni02LjU3LTIuODU2Yy0yLjQ3MSwwLTQuNjYxLDAuOTUtNi41NjMsMi44NTZMMTQyLjQ2NiwxNzQuNDQxICAgTDMwLjI2Miw2Mi4yNDFjLTEuOTAzLTEuOTA2LTQuMDkzLTIuODU2LTYuNTY3LTIuODU2Yy0yLjQ3NSwwLTQuNjY1LDAuOTUtNi41NjcsMi44NTZMMi44NTYsNzYuNTE1QzAuOTUsNzguNDE3LDAsODAuNjA3LDAsODMuMDgyICAgYzAsMi40NzMsMC45NTMsNC42NjMsMi44NTYsNi41NjVsMTMzLjA0MywxMzMuMDQ2YzEuOTAyLDEuOTAzLDQuMDkzLDIuODU0LDYuNTY3LDIuODU0czQuNjYxLTAuOTUxLDYuNTYyLTIuODU0TDI4Mi4wODIsODkuNjQ3ICAgYzEuOTAyLTEuOTAzLDIuODQ3LTQuMDkzLDIuODQ3LTYuNTY1QzI4NC45MjksODAuNjA3LDI4My45ODQsNzguNDE3LDI4Mi4wODIsNzYuNTExeiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);

    option {
        color: $body-color !important;
    }
}

// Interactive Credit Card
.card-wrapper {
    margin: 30px 0;
}

@media (max-width: $screen-sm) {
    .jp-card-container {
        width: 285px !important;
    }

    .jp-card {
        min-width: 250px !important;
    }
}

// Coupon form
.coupon-form {
    .form-control {
        display: inline-block;
        width: 100%;
        max-width: 235px;
        margin-right: 12px;
    }

    .btn {
        margin-right: 0;
    }

    @media (max-width: $screen-md) {
        .form-control {
            display: block;
            max-width: 100%;
        }
    }
}

// Validation states
.was-validated {
    .form-control {

        &:valid,
        &.is-valid {
            border-color: shade-color($border-color, 2%) !important;

            &:focus {
                border-color: $brand-primary !important;
            }
        }

        &:invalid,
        &.is-invalid {
            border-color: $brand-danger !important;
        }

        &.input-light {

            &:valid,
            &.is-valid {
                border-color: rgba-var($white-color, 0.18) !important;
                background-color: rgba-var($white-color, 0.02) !important;

                &:focus {
                    border-color: rgba-var($white-color, 0.3) !important;
                    background-color: rgba-var($white-color, 0.04) !important;
                }
            }

            &:invalid,
            &.is-invalid {
                border-color: $brand-danger !important;
            }
        }
    }

    .form-control,
    .form-control.input-light {

        &:valid,
        &.is-valid {
            padding-right: 42px;

            background: {
                position: center right 15px;
                image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iY2hlY2stY2lyY2xlLW91dGxpbmUiPgoJCTxwYXRoIGQ9Ik0xNTAuNDUsMjA2LjU1bC0zNS43LDM1LjdMMjI5LjUsMzU3bDI1NS0yNTVsLTM1LjctMzUuN0wyMjkuNSwyODUuNkwxNTAuNDUsMjA2LjU1eiBNNDU5LDI1NWMwLDExMi4yLTkxLjgsMjA0LTIwNCwyMDQgICAgUzUxLDM2Ny4yLDUxLDI1NVMxNDIuOCw1MSwyNTUsNTFjMjAuNCwwLDM4LjI1LDIuNTUsNTYuMSw3LjY1bDQwLjgwMS00MC44QzMyMS4zLDcuNjUsMjg4LjE1LDAsMjU1LDBDMTE0Ljc1LDAsMCwxMTQuNzUsMCwyNTUgICAgczExNC43NSwyNTUsMjU1LDI1NXMyNTUtMTE0Ljc1LDI1NS0yNTVINDU5eiIgZmlsbD0iIzRjYWY1MCIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
                repeat: no-repeat;
                size: 17px 17px;
            }
        }
    }

    .form-control,
    .form-control.input-light {

        &:invalid,
        &.is-invalid {
            padding-right: 42px;

            background: {
                position: center right 15px;
                image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ5MS44NTggNDkxLjg1OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkxLjg1OCA0OTEuODU4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTI0NS45MjksMEMxMTAuMTA2LDAsMCwxMTAuMTA2LDAsMjQ1LjkyOXMxMTAuMTA2LDI0NS45MjksMjQ1LjkyOSwyNDUuOTI5YzEzNS44MjIsMCwyNDUuOTI5LTExMC4xMDYsMjQ1LjkyOS0yNDUuOTI5ICAgIFMzODEuNzUxLDAsMjQ1LjkyOSwweiBNNDMuNzIxLDI0NS45MjljMC0xMTEuNjc3LDkwLjUzMS0yMDIuMjA4LDIwMi4yMDgtMjAyLjIwOGM0Ni4xNDQsMCw4OC42NjgsMTUuNDY3LDEyMi42OTYsNDEuNDg1ICAgIEw4NS4yMDQsMzY4LjYyNUM1OS4xODcsMzM0LjU5Nyw0My43MjEsMjkyLjA3Miw0My43MjEsMjQ1LjkyOXogTTI0NS45MjksNDQ4LjEzN2MtNDUuODI4LDAtODguMDg3LTE1LjI1NS0xMjItNDAuOTUgICAgTDQwNy4xODYsMTIzLjkzYzI1LjY5NCwzMy45MTEsNDAuOTQ5LDc2LjE3MSw0MC45NDksMTIxLjk5OUM0NDguMTM2LDM1Ny42MDUsMzU3LjYwNSw0NDguMTM3LDI0NS45MjksNDQ4LjEzN3oiIGZpbGw9IiNmNDQzMzYiLz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
                repeat: no-repeat;
                size: 17px 17px;
            }
        }
    }

    .custom-file-input:valid~.custom-file-label,
    .custom-file-input.is-valid~.custom-file-label {
        border-color: shade-color($border-color, 1%) !important;
    }

    .custom-control-input {

        &:invalid~.custom-control-label,
        &.is-invalid~.custom-control-label {
            color: $brand-danger;

            &::before {
                border-color: $brand-danger;
            }
        }

        &:valid,
        &.is-valid {
            &~.custom-control-label {
                color: $body-color;

                &::before {
                    background-color: #dee2e6;
                }
            }

            &:checked {
                &~.custom-control-label {
                    color: $body-color;

                    &::before {
                        border-color: $brand-primary;
                        background-color: $brand-primary;
                    }
                }
            }
        }
    }
}

.valid-feedback,
.invalid-feedback {
    margin-top: 5px;
    font-size: 80% !important;
}

.valid-tooltip,
.invalid-tooltip {
    padding: {
        top: 2px;
        bottom: 2px;
    }

    font-size: 80%;
}

.valid-feedback {
    color: $brand-success;
}

.invalid-feedback {
    color: $brand-danger;
}

.valid-tooltip {
    background-color: $brand-success;
}

.invalid-tooltip {
    background-color: $brand-danger;
}