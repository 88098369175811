.home-carousel-slide {
    &>.row {
        justify-content: center;
        align-items: center;

        &>div:first-child {
            // Left column (text + button)
            text-align: center;
            @extend .text-md-start;
            @include padding-bottom(2);
        }

        &>div:nth-child(2) {
            // Right column (image)
            margin-bottom: 1rem;

            &>img,
            &>svg {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.home-carousel-slide-text {
    @extend .from-bottom;
    font-size: $font-size-h2;
    font-weight: $font-weight-h2;
    color: $black-color;
    margin-bottom: 0.5rem;
    padding-top: 0.25rem;
}

.home-carousel-slide-button {
    @extend .scale-up;
    @extend .delay-1;
    display: inline-block;
}

.home-carousel-slide-image {
    text-align: center;

    img,
    svg {
        display: inline-block;
    }
}