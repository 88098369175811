.proxy-search-result {
    position: relative;
    padding: 0.75rem;
    text-align: center;
    border: 1px solid $border-color;
    transition: border-color 0.2s;
    border-radius: $border-radius-base;
    background-color: $white-color;
    word-break: break-word;
    @include margin-bottom(1);
    cursor: pointer;
    &:hover {
        border-color: rgba-var($primary, 0.75);
        &::before {
            color: rgba-var($primary, 0.75);
        }
        & .value--full-name {
            color: $primary;
        }
    }

    &::before {
        transition: color 0.2s;
        position: absolute;
        color: $border-color;
        top: 0;
        left: 0.25rem;
        font-size: 3rem;
    }
    &.user::before {
        @include icon('user');
    }
    &.catalog::before {
        @include icon('folder');
    }

    & .value--full-name {
        transition: color 0.2s;
        font-size: $font-size-lead;
        margin-left: 2.5rem;
    }
    & .value--user-id {
        font-size: $font-size-sm;
        margin-left: 2.5rem;
    }
    & .value--user-details {
        color: $gray;
        font-size: $font-size-sm;
        min-height: 3em;
    }
}
