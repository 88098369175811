.sure-preview--toolbox {
    .toolbox-group {
        @include margin-top(1);
        @include margin-bottom(1);
        .toolbox-group--title {
            @include margin-bottom(0.5);
            font-size: $font-size-base;
            line-height: $line-height-h6;
            font-weight: normal;
            color: inherit;
        }
    }
}