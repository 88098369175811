.context-menu {
    @extend %sub-menu-like;
    width: max-content;
    max-width: $screen-xs;
    overflow-wrap: break-word;
    @media (max-width: $screen-xs) {
        max-width: 100vw;
    }
    z-index: 1;
    > li {
        &:hover,
        &:focus-within,
        &.active {
            > %sub-menu-action-item {
                background-color: $dropdown-link-hover-bg;
                color: $dropdown-link-hover-color;

                .context-menu--entry--icon {
                    @include outlined-image();
                }
            }
        }
        
        &.has-icon > %sub-menu-action-item {
            display: grid;
            grid-template-columns: min-content auto;
            gap: 0.5rem;
            align-items: center;

            .context-menu--entry--icon {
                @include centered-icon-container(30px);
            }
        }
    }
}

.context-menu--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}