.sure-preview--modal--column {
    padding: 0.25rem;
    text-align: center;

    .sure-preview--modal--option-selection {
        width: 100%;
        height: auto;
        padding: 0.5rem;
        line-height: normal;
        white-space: break-spaces;

        .formatted-string {
            white-space: break-spaces;
        }

        h6 {
            color: unset;
        }
    }
}