.missing-contacts-button-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
}

.fixAddressButton {
    margin: {
        right: 15px;
    }
}