.stylized-select {
    .dropdown-toggle {
        display: grid;
        grid-template-columns: auto min-content;
        align-items: center;
        text-align: left;

        &.is-invalid {
            border-color: $brand-danger !important;
        }
    }

    .dropdown-menu {
        width: 100%;
        max-height: 360px;
        overflow-y: auto;

        .dropdown-item::before {
            // Zero-width space, to make sure we always take up the right vertical space even if the option is the empty string
            content: "​";
        }
    }
}

.form-floating {
    >.stylized-select {
        &>.form-control {
            height: $form-floating-height;
            line-height: $form-floating-line-height;
            padding-left: $form-floating-padding-x;
            padding-right: $form-floating-padding-x;
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }

        ~label {
            color: $form-floating-label-color;
            opacity: $form-floating-label-opacity;
            transform: $form-floating-label-transform;
        }
    }
}

.form-label-on-left {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap: 0.5em;

    label {
        color: $gray;
    }

    @media (max-width: $screen-sm) {
        grid-template-columns: 100%;
    }
}